import React, { useState } from "react";
import {motion} from "framer-motion";
export const TransactionTab = ({selectedIds, handleTabClick, activeTab, handleRefresh, isAnimation}) => {

    const rotateAnimation = isAnimation ? { rotate: 360, transition: {duration: 1, repeat: Infinity} } : { rotate: 0, transition: {duration: 0, repeat: 0} };
    return(
        <div onClick={() => handleTabClick("games")} className={`button ${activeTab === "games" ? "active" : ""}`}>
                    <span>
                        Транзакции по играм

                    </span>
            <span className='walletBalanceRefresh'>
                        <span className={'downloadFileSpan'}>
                            {activeTab === "games" && Object.keys(selectedIds).length > 0 &&
                                <>
                                    <span>{Object.keys(selectedIds).length}</span>
                                    <motion.button
                                        whileHover={{scale: 0.95}}
                                        whileTap={{scale: 0.8}}
                                        className='bx bxs-cloud-download downloadFile'>
                                    </motion.button>
                                </>
                            }
                        </span>
                        <span className={'tabRefreshButton'}>
                            <motion.button
                                onClick={handleRefresh}
                                className="bx bx-refresh headerFormButton"
                                whileHover={{scale: 0.9}}
                                animate={rotateAnimation}
                            >
                        </motion.button>
                        </span>
                    </span>
        </div>
    );
}