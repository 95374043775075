// import {useState} from "react";
import {ReactComponent as Warning} from "../../img/warning.svg";
import {motion} from "framer-motion";

export const ErrorPopup = ({message, handleErrorVision}) => {
    // const [isVision, setIsVision] = useState(false)

    return(
        <motion.div
            whileHover={{scale: 1.04}}
            whileTap={{scale: 0.98}}
            initial={{x: -400, opacity: 0}}
            animate={{x: 0, opacity: 1}}
            transition={{duration: 0.2}}
            exit={{x: -400}}
            onClick={handleErrorVision}
            className="errorPopupBox">
            <div
                className="message_container"
            >
                <motion.div
                    className="timer_bar"
                    initial={{width: '100%'}}
                    animate={{width: 0}}
                    transition={{duration: 5}}
                />
                <div className="errorPopupIcon">
                    <Warning/>
                </div>
                <div className="errorPopupMessage">
                    {message}
                </div>
            </div>
            {/*<div className="errorPopupButton">*/}
            {/*    <span*/}
            {/*    className="close-btn bx bx-x"*/}
            {/*    ></span>*/}
            {/*</div>*/}
        </motion.div>
    )
}