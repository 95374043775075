import {motion} from "framer-motion";
import {useState} from "react";
import style from '../transaction.module.css'
export const ServiceTitle = ({key, service}) => {
    const [isVisible, setVisible] = useState(false);

    const handleVisible = () => {
        setVisible(!isVisible);
    }

    return(
        <motion.span
            onClick={handleVisible}
            className={style.service_title}
            animate={{width: isVisible ? '100%' : '62px'}}
            key={key}>
            {service}
        </motion.span>
    )
}