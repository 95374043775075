import {useState, useEffect} from "react";
import {getToServer} from "../ToServer/getToServer";
import {sendToServer} from "../ToServer/sendToServer";
import serverLink from "../ServerLink";
import {ServiceContent} from "./serviceContent";
import ServerLink from "../ServerLink";
import Loader from "../Loader";

const Service = () => {
    const [serviceData, setServiceData] = useState({data: [], isLoading: true, errors: null})
    const [data, setData] = useState({
        digiFields: [{ digiId: '', digiName: '' }],
        serviceFields: [{ serviceId: '', serviceName: '' }],
        optionFields: [{ optionId: '', optionIdDigi: '', optionName: '' }],
        subOptionFields: [{ subOptionId: '', subOptionIdDigi: '', subOptionName: '' }],
        name: ''
    });

    const handleSendData = () => {
        // console.log(data);
        sendToServer('serviceAdd', ServerLink().addToListUrl, data, handleSilentRefresh);
        // handleSilentRefresh();
    };

    useEffect(() => {
        getToServer('services', serverLink().serviceListUrl, '', setServiceData, () => {}, 'noSilent');
        // console.log(serviceData);
    }, []);

    const handleDelete = (id) => {
        const data = {id: id}
        sendToServer('serviceDelete', serverLink().deleteListUrl, data, handleSilentRefresh);
    }

    const handleSilentRefresh = () => {
        getToServer('services', serverLink().serviceListUrl, '', setServiceData, () => {}, 'silent');
    }

    return(
        <>
            {serviceData.isLoading ? (
                <Loader/>
            ):
                <ServiceContent
                    serviceData={serviceData}
                    setServiceData={setServiceData}
                    data={data}
                    setData={setData}
                    handleSendData={handleSendData}
                    handleDelete={handleDelete}
                />
            }
        </>
    )
}

export default Service;