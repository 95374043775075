import {motion, AnimatePresence} from "framer-motion";
import {useState} from "react";
import {ControlButtons} from "./controlButtons";
export const AccountControl = () => {
    const [isControlVisible, setControlVisible] = useState(false)
    const handleControlVisible = () => {
        setControlVisible(!isControlVisible);
    }


    return(
            <motion.div
                className="account_control"
            >
                <motion.span
                    onClick={handleControlVisible}
                    whileHover={{backgroundColor: 'var(--border-color)'}}
                    whileTap={{scale: 0.9}}
                    className="account_control_button">
                    <i
                        className={`bx ${isControlVisible ? `bxs-chevron-right` : 'bxs-chevron-left'}`}
                    ></i>
                </motion.span>
                <AnimatePresence>
                    {isControlVisible &&
                        <ControlButtons/>
                    }
                </AnimatePresence>
            </motion.div>
    )
}