import React, { useState, useEffect } from 'react';
import ServerLink from "../ServerLink";
import Loader from "../Loader";
import {getToServer} from "../ToServer/getToServer";
import {sendToServer} from "../ToServer/sendToServer";
import {AccountsContent} from "./accountsContent";
import {useError} from "../Error/errorContext";

export const Accounts = () => {
    const [tableData, setTableData] = useState({ data: [], isLoading: true, error: null });
    const [selectedParentId, setSelectedParentId] = useState(null);
    const {setError} = useError();


    useEffect(() => {
        getToServer('accountsTree', ServerLink().serviceListUrl, 'None', setTableData);
    }, []);
    useEffect( () => {
        if (tableData.error){
            setError(tableData.error)
        }
    }, [tableData.error]);

    const handleSilentUpdate = () => {
        getToServer('accountsTree', ServerLink().serviceListUrl, 'None', setTableData, () => ({}), 'silent');
    };

    const handleDelete = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить аккаунт?")) {
            sendToServer('deleteAccount', ServerLink().EditGameUrl, itemId);
        }
    };
    const  handleDeleteSession = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить сессию?")) {
            sendToServer('deleteSession', ServerLink().BufferUrl, itemId);
        }
    };
    const  handleDeleteFriends = (itemId) => {
        if (window.confirm("Вы уверены, что хотите удалить всех друзей на аккаунте?")) {
            sendToServer('deleteFriends', ServerLink().BufferUrl, itemId);
        }
    };

    const handleAccountBalance = () => {
        sendToServer('updateBalance', ServerLink().BufferUrl, 'None');
    };
    const handleParentIdChange = (tableData) => {
        const data = {
            accounts: tableData
        };

        sendToServer('updateParentId', ServerLink().updateListUrl, data, handleSilentUpdate);
    };

    return (
        <>
            {tableData.isLoading ? (
                <Loader />
            ) : (
                <AccountsContent
                    tableData = {tableData.data}
                    setTableData = {setTableData}
                    handleParentIdChange={handleParentIdChange}
                />
            )}
        </>
    );
}