import React, { useEffect, useState, useMemo } from "react";
import ServerLink from "./ServerLink";

function Status() {
    const [queueCount, setQueueCount] = useState(0);
    // const [errorCount, setErrorCount] = useState(0);

    // const data = useMemo(() => {
    //     return {
    //         object: "lines",
    //     };
    // }, []);
    //
    // useEffect(() => {
    //     const fetchData = async () => {
    //         const requestOptions = {
    //             method: "POST",
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": "Bearer " + localStorage.getItem("token")
    //             },
    //             body: JSON.stringify(data),
    //         };
    //
    //         try {
    //             const response = await fetch(ServerLink().LinesUrl, requestOptions);
    //             const responseData = await response.json();
    //             setQueueCount(responseData.queueCount);
    //             // setErrorCount(responseData.errorCount);
    //         } catch (error) {
    //             console.error("Error fetching data:", error);
    //         }
    //     };
    //
    //     fetchData();
    //
    // }, [data]);

    return (
        <div className="card">
            <div className="card_row">
                <div className="card_placeholder">
                    <div className="stats">
                        <span className="stats_title">На очереди</span>
                        <span className="stats_info">{queueCount}</span>
                    </div>
                    <div className="stats_img">
                        <i className="bx bx-time-five"></i>
                    </div>
                </div>
                <div className="card_placeholder">
                    <div className="stats">
                        <span className="stats_title">Ошибочные транзакции</span>
                        <span className="stats_info">0</span>
                    </div>
                    <div className="stats_img error">
                        <i className="bx bx-error"></i>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Status;
