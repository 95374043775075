import {motion} from "framer-motion";
function SteamStatusCard({message}) {
    return(
        <motion.div

            className="card steam_status">
                        <span className="steam_status_img">
                            <i className="bx bx-info-circle"></i>
                        </span>
            <span className="steam_status_title">
                            {/*Steam сервера сейчас перегружены, мы делаем все чтобы Вы как можно скорее получили Вашу игру.*/}
                            {/*Выдача может задерживаться на пару часов!*/}
                {message}
                        </span>
        </motion.div>
    )
}

export default SteamStatusCard;