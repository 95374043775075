import React, {useState, useEffect} from 'react';
import Loader from "../Loader";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {motion} from "framer-motion";

const Transactions = ({showAll, setShowAll, tableData, isLoading, handleSilentRefresh, startDate, endDate, setStartDate, setEndDate, selectedIds, setSelectedIds, handleCheckboxChange, handleSelectAll}) => {
    const [isActiveDate, setIsActiveDate] = useState(false);
    const handleShowAll = () => {
        setSelectedIds({});
        setShowAll(true); // Устанавливаем флаг, чтобы показать все транзакции
    };
    const handleIsActiveDate = () => setIsActiveDate(!isActiveDate);
    const handleClearFilter = () => {
        setSelectedIds({});
        setShowAll(false);
        setStartDate(null);
        setEndDate(null);
    };


    return (
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <OptimizedTable
                        tableData={showAll ? tableData : tableData.slice(0, 100)}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        isActiveDate={isActiveDate}
                        handleIsActiveDate={handleIsActiveDate}
                        handleClearFilter={handleClearFilter}
                        handleCheckboxChange={handleCheckboxChange}
                        selectedIds={selectedIds}
                        handleSelectAll={handleSelectAll}
                    />
                    {!showAll && (
                        <div className="showAll_button">
                            <button onClick={handleShowAll}>Загрузить все</button>
                        </div>
                    )}
                </>
            )}
        </>
    );
}

const TableRow = React.memo(({item, onCheckboxChange, isSelected}) => (
    <tr key={item.id}>
        <td className={'checkBox'}>
            <motion.input
                type="checkbox"
                checked={isSelected}
                onChange={(e) => onCheckboxChange(item.id, e.target.checked)}
                whileHover={{scale: 0.96}}
                whileTap={{scale: 0.85}}
            />
        </td>
        <td>{item.id}</td>
        <td>{item.code}</td>
        <TableCell value={item.name}/>
        <td>{item.steamLogin}</td>
        <td>{item.region}</td>
        <TableCell value={item.acc_url}/>
        <td>{item.purchasePrice}</td>
        <td>{item.purchasePriceUSD}</td>
        <td>{item.date}</td>
        <td>
                    <span className={`statusSpan`}>
                        <span>
                            {/*<i*/}
                            {/*    className={`bx ${*/}
                            {/*        item.status.includes("отправлена") ? "bxs-circle success" :*/}
                            {/*            item.status.includes("обработку") ? "bxs-circle warning" : "bxs-circle error"*/}
                            {/*    }`}*/}
                            {/*></i>*/}
                            <span className='statusSmile'>
                                {
                                    item.status.includes("отправлена") ? "🙂" :
                                        item.status.includes("обработку") ? "🥶" : "😡"
                                }
                            </span>
                            {item.status}
                        </span>
                    </span>
        </td>
        <td></td>
    </tr>
));

const TableCell = React.memo(({value}) => (
    <td className="hidden">
        <span className="visibility_text">{value}</span>
        <span title="Нажмите, чтобы скопировать" className="hover_text">
            {value}
        </span>
    </td>
));

const OptimizedTable = ({
                            tableData,
                            isLoading,
                            startDate,
                            endDate,
                            setStartDate,
                            setEndDate,
                            isActiveDate,
                            handleIsActiveDate,
                            handleClearFilter,
                            handleCheckboxChange,
                            selectedIds,
                            handleSelectAll
                        }) => (
    <>
        {isLoading ? (
            <Loader />
        ) : (
            <div className="table_content">
                <table>
                    <thead>
                    <tr>
                        <th className={'checkBox'}>
                            <motion.input
                                type="checkbox"
                                onChange={(e) => handleSelectAll(e.target.checked, tableData)}
                                checked={tableData.every(item => selectedIds[item.id])}
                                whileHover={{scale: 0.96}}
                                whileTap={{scale: 0.85}}
                            />
                        </th>
                        <th>
                            <span>ID</span>
                        </th>
                        <th>Код транзакции</th>
                        <th>Название игры</th>
                        <th>Аккаунт выдачи</th>
                        <th>Рег</th>
                        <th>Ссылка на аккаунт</th>
                        <th>Цена</th>
                        <th>Цена USD</th>
                        <th className='filterData'>
                            <motion.div
                                className='filterDataSpan'
                                onClick={handleIsActiveDate}
                                whileHover={{color: '#b773ff'}}
                                whileTap={{color: '#fff', transition: {duration: 0.2}}}
                            >
                                <span>
                                    Дата
                                </span>
                                <i className='bx bxs-sort-alt'></i>
                            </motion.div>
                            {isActiveDate &&
                                <div className="filter_controls">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText="От"/>
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText="До"/>
                                    <motion.button
                                        whileHover={{scale: 0.96}}
                                        whileTap={{scale: 0.95}}
                                        className={'filterClearButton'}
                                        onClick={handleClearFilter}>
                                        Очистить
                                    </motion.button>
                                </div>
                            }
                        </th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item) => (
                        <TableRow key={item.id}
                                  item={item}
                                  onCheckboxChange={handleCheckboxChange}
                                  handleSelectAll={handleSelectAll}
                                  isSelected={!!selectedIds[item.id]}/>
                    ))}
                    </tbody>
                </table>
            </div>
        )}
    </>
)

export default Transactions;
