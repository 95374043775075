import Loader from "../Loader";
import {TableRow} from "./tableRow";
import React, {useMemo, useState} from "react";
import style from './games.module.css'
import {motion} from "framer-motion";
import {RowCount} from "./components/rowCount";
export const Table = React.memo(({ tableData, isLoading, setTableData, handleRefresh}) => {
    const [isUrlVisible, setUrlVisible] = useState(false)
    const [isTitleVisible, setTitleVisible] = useState(false)
    const [maxValue, setMaxValue] = useState(250)
    const [minValue, setMinValue] = useState(0)
    const handleUrlVisible = () => {
        setUrlVisible(!isUrlVisible)
    }
    const handleTitleVisible = () => {
        setTitleVisible(!isTitleVisible)
    }

    const rows = useMemo(() =>
        tableData.slice(minValue, maxValue).map(item => (
            <TableRow
                key={item.id}
                item={item}
                setTableData={setTableData}
                handleRefresh={handleRefresh}
                isUrlVisible={isUrlVisible}
                isTitleVisible={isTitleVisible}
            />
        )), [tableData, setTableData, handleRefresh, maxValue, isUrlVisible, isTitleVisible]);

    return (
        <>
            {isLoading ? (
                <Loader/>
            ) : (
                <div className="table_content">
                    <table className="tableGames">
                        <thead>
                        <tr>
                            <th>
                                <span className={style.filter_box}>
                                    <span>№</span>
                                    <RowCount
                                        maxValue={maxValue}
                                        setMaxValue={setMaxValue}/>
                                </span>
                            </th>
                            <th>Площадки/наценка</th>
                            <th>
                                <span className={style.th_more}>
                                    <span>
                                    Ссылка на игру
                                    </span>
                                    <motion.span onClick={handleUrlVisible}
                                                 animate={{ rotate: isUrlVisible ? 180 : 0 }}
                                                 className={`bx bxs-chevron-right ${style.button_more}`}>
                                    </motion.span>
                                </span>
                            </th>
                            <th>
                                <span className={style.th_more}>
                                    <span>
                                        Название игры
                                    </span>
                                    <motion.span onClick={handleTitleVisible}
                                                 animate={{rotate: isTitleVisible ? 180 : 0}}
                                                 className={`bx bxs-chevron-right ${style.button_more}`}>
                                    </motion.span>
                                </span>
                            </th>
                            <th>Аккаунт выдачи</th>
                            <th>Рег.</th>
                            <th>Цена Steam</th>
                            <th>Скидка</th>
                            <th>Цена по скидке</th>
                            <th>Цена продажи</th>
                            <th>Выкл</th>
                            <th>Дата обновления</th>
                            <th>Удалить</th>
                        </tr>
                        </thead>
                        <tbody>
                            {rows}
                        </tbody>
                    </table>
                </div>
            )}
        </>
    )
});