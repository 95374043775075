import React, {useEffect, useState} from "react";
import ServerLink from "../ServerLink";
import Loader from "../Loader";
import {ServiceTitle} from "./components/serviceTitle";
import {sendToServer} from "../ToServer/sendToServer";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import {motion} from "framer-motion";


const TransactionsQiwi = ({
                              showAll,
                              setShowAll,
                              tableData,
                              isLoading,
                              handleSilentRefresh,
                              startDate,
                              endDate,
                              setStartDate,
                              setEndDate,
                              selectedIds,
                              setSelectedIds,
                              handleCheckboxChange,
                              handleSelectAll
                        }) => {

    const handleShowAll = () => {
        setShowAll(true); // Устанавливаем флаг, чтобы показать все транзакции
    };
    const handleClearFilter = () => {
        setShowAll(false);
        setStartDate(null);
        setEndDate(null);
    };
    const handleTransaction = (login, amount, code) => {
        if(window.confirm('Вы уверены что хотите выдать повторно?')){
            const data = {
                code: code
            }
            sendToServer('topUpBalance', ServerLink().topUpBalanceUrl, data);
        };
    };

    const [isActiveDate, setIsActiveDate] = useState(false);

    const handleIsActiveDate = () => setIsActiveDate(!isActiveDate);

    return(
        <>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <OptimizedTable
                        handleSilentRefresh={handleSilentRefresh}
                        handleTransaction={handleTransaction}
                        tableData={showAll ? tableData : tableData.slice(0, 100)}
                        startDate={startDate}
                        endDate={endDate}
                        setStartDate={setStartDate}
                        setEndDate={setEndDate}
                        isActiveDate={isActiveDate}
                        handleIsActiveDate={handleIsActiveDate}
                        handleClearFilter={handleClearFilter}
                        selectedIds={selectedIds}
                        handleCheckboxChange={handleCheckboxChange}
                        handleSelectAll={handleSelectAll}
                    />
                    {!showAll && (
                        <div className="showAll_button">
                            <button onClick={handleShowAll}>Загрузить еще</button>
                        </div>
                    )}
                </>
            )}
        </>
    )
}

const TableRow = React.memo(({ item, handleTransaction, handleSilentRefresh, onCheckboxChange, isSelected }) => {
    const [isAnimation, setIsAnimation] = useState(false);

    const handleLoginEdit = (event, itemId) => {
        const newText = event.target.innerText;
        if (newText !== item.steam_login) {
            const data = {
                id: itemId,
                steamLogin: newText
            }
            setIsAnimation(true)
            sendToServer('updateLoginWallet', ServerLink().updateListUrl, data, () => setIsAnimation(false));
            handleSilentRefresh();
        }
    };

    return(
        <>
            <tr key={item.id}>
                <td className={'checkBox'}>
                    <motion.input
                        type="checkbox"
                        checked={isSelected}
                        onChange={(e) => onCheckboxChange(item.id, e.target.checked)}
                        whileHover={{scale: 0.96}}
                        whileTap={{scale: 0.85}}
                    />
                </td>
                <td>{item.id}</td>
                <td>{item.code}</td>
                <td>{item.code_api}</td>
                <td>
                    <ServiceTitle
                        key={item.service_title}
                        service={item.service_title}
                    />
                </td>
                <td>
                    <ServiceTitle
                        key={item.option_title}
                        service={item.option_title}
                    />
                </td>
                <td>
                    <span className={`input_box`}>
                        <motion.span
                            whileHover={{scale: 1.1}}
                            whileTap={{scale: 1}}
                            whileFocus={{borderBottom: '1px solid var(--white-color)', transition: {duration: 0.5}}}
                            className={`editable_cell ${isAnimation ? 'animation' : ''}`}
                            onBlur={(event) => handleLoginEdit(event, item.id)}
                            suppressContentEditableWarning={true} contentEditable
                        >
                        {item.steam_login}
                    </motion.span>
                    </span>
                </td>
                <td>{item.amount}</td>
                <td>{item.amount_usd}</td>
                <td>{item.date}</td>
                <td>
                    <span className={`statusSpan`}>
                        <span>
                            <span className='statusSmile'>
                                {
                                    item.status.includes("успешно") ? "🙂" :
                                        item.status.includes("ошибка") ? "🥶" : "😡"
                                }
                            </span>
                            {item.status}
                        </span>
                    </span>
                </td>
                <td>
                    <motion.button
                        onClick={() => handleTransaction(item.steam_login, item.amount, item.code)}
                        className={'sendQiwiButton'}
                        whileHover={{scale: 0.95}}
                        whileTap={{scale: 0.9}}
                    ><i className='bx bxs-send'></i>
                        <span>
                            Выдать
                        </span>
                    </motion.button>
                </td>
            </tr>
        </>
    )
    }
);

const OptimizedTable = ({tableData,
                        isLoading,
                        handleTransaction,
                        handleSilentRefresh,
                        startDate,
                        endDate,
                        setStartDate,
                        setEndDate,
                        isActiveDate,
                        handleIsActiveDate,
                        handleClearFilter,
                        handleCheckboxChange,
                        selectedIds,
                        handleSelectAll
}) => (
    <>
        {isLoading ? (
            <Loader/>
        ) : (
            <div className="table_content">
                <table>
                    <thead>
                    <tr>
                        <th className={'checkBox'}>
                            <motion.input
                                type="checkbox"
                                onChange={(e) => handleSelectAll(e.target.checked, tableData)}
                                checked={tableData.every(item => selectedIds[item.id])}
                                whileHover={{scale: 0.96}}
                                whileTap={{scale: 0.85}}
                            />
                        </th>
                        <th>
                            <span>Id</span>
                        </th>
                        <th>Код транзакции</th>
                        <th>Код по api</th>
                        <th>Сервис</th>
                        <th>Опция</th>
                        <th>Логин</th>
                        <th>Кол-во (шт. / ₽)</th>
                        <th>Сумма $</th>
                        <th className='filterData'>
                            <motion.div
                                className='filterDataSpan'
                                onClick={handleIsActiveDate}
                                whileHover={{color: '#b773ff'}}
                                whileTap={{color: '#fff', transition: {duration: 0.2}}}
                            >
                                <span>
                                    Дата
                                </span>
                                <i className='bx bxs-sort-alt'></i>
                            </motion.div>
                            {isActiveDate &&
                                <div className="filter_controls">
                                    <DatePicker selected={startDate} onChange={(date) => setStartDate(date)}
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText="От"/>
                                    <DatePicker selected={endDate} onChange={(date) => setEndDate(date)}
                                                dateFormat="dd.MM.yyyy"
                                                placeholderText="До"/>
                                    <motion.button
                                        whileHover={{scale: 0.96}}
                                        whileTap={{scale: 0.95}}
                                        className={'filterClearButton'}
                                        onClick={handleClearFilter}>
                                        Очистить
                                    </motion.button>
                                </div>
                            }
                        </th>
                        <th>Статус</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((item) => (
                        <TableRow key={item.id}
                                  item={item}
                                  handleTransaction={handleTransaction}
                                  handleSilentRefresh={handleSilentRefresh}
                                  onCheckboxChange={handleCheckboxChange}
                                  handleSelectAll={handleSelectAll}
                                  isSelected={!!selectedIds[item.id]}
                        />
                    ))}
                    </tbody>
                </table>
            </div>
        )}
    </>
)

export default TransactionsQiwi;