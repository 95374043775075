import {motion} from "framer-motion";
export const ControlButtons = () => {

   return(
       <motion.div
           initial={{x: 400, opacity: 0}}
           animate={{x: 0, opacity: 1}}
           transition={{duration: 0.2}}
           exit={{x: 400}}
           className="account_control_panel"
       >
           <div>
               <motion.span
                   whileHover={{opacity: 0.7}}
                   className="button_refresh"
               >
                   Обновить баланс
               </motion.span>
           </div>
           <div>
               <motion.span
                   whileHover={{opacity: 0.7}}
                   className="button_delete"
               >
                   Удалить друзей
               </motion.span>
           </div>
           <div>
               <motion.span
                   whileHover={{opacity: 0.7}}
                   className="button_delete"
               >
                   Удалить аккаунт
               </motion.span>
           </div>
       </motion.div>
   )
}