import {motion, AnimatePresence} from "framer-motion";
import style from "./components.module.css"
import {useCallback, useState} from "react";

export const DigisellerAdd = ({isVisible, game_id}) => {
    const [boxIsVisible, setBoxVisible] = useState(false)
    const [values, setValues] = useState({
        markup: '30',
        title: 'GGSel',
        digi: '01234567'
    });

    const handleChange = useCallback((event) => {
        const { name } = event.target.dataset;
        const value = event.target.innerText; // Получаем текстовое содержимое span
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }, []);

    const handleEnter = useCallback((e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.target.blur();
        }
    }, []);

    const handleBoxVisible = () => {
        setBoxVisible(!boxIsVisible);
    }

    return(
        <AnimatePresence mode={`wait`}>
            {isVisible &&
                <motion.span
                    exit={{width: 0, height: 0, opacity: 0}}
                    initial={{width: 0, height: 0, opacity: 0}}
                    animate={{width: 'auto', height: 'auto', opacity: 1}}
                    style={{overflow: "hidden"}}
                    className={style.add_button_box}>
                    <motion.span
                        animate={{
                            rotate: boxIsVisible ? 45 : 0,
                            color: boxIsVisible ? 'var(--red-color)' : 'var(--white-color)'
                        }}
                        onClick={handleBoxVisible}
                        className={`${style.add_button} bx bx-plus`}></motion.span>
                    <AnimatePresence mode={`wait`}>
                        {boxIsVisible &&
                            <motion.span
                                exit={{width: 0, opacity: 0}}
                                initial={{width: 0, opacity: 0}}
                                animate={{width: 'auto', opacity: 1}}
                                style={{overflow: "hidden"}}
                                className={style.add_box}>
                            <span
                                className={style.main_markup}>
                                <span
                                    className={style.row_markup}>
                                    <motion.span
                                        whileTap={{scale: 0.96}}
                                        whileHover={{scale: 1.02}}
                                        whileFocus={{borderColor: 'var(--white-color)'}}
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        data-name="markup"
                                        onBlur={handleChange}
                                        onKeyDown={handleEnter}
                                        className={`${style.box_markup} 
                                        ${style.markup}`}>
                                        {values.markup}
                                    </motion.span>
                                    <motion.span
                                        whileTap={{scale: 0.96}}
                                        whileHover={{scale: 1.02}}
                                        whileFocus={{borderColor: 'var(--white-color)'}}
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        data-name="title"
                                        onBlur={handleChange}
                                        onKeyDown={handleEnter}
                                        className={style.box_markup}>
                                        {values.title}
                                    </motion.span>
                                </span>
                                <span
                                    className={style.row_markup}>
                                    <motion.span
                                        whileTap={{scale: 0.96}}
                                        whileHover={{scale: 1.02}}
                                        whileFocus={{borderColor: 'var(--white-color)'}}
                                        contentEditable={true}
                                        suppressContentEditableWarning={true}
                                        data-name="digi"
                                        onBlur={handleChange}
                                        onKeyDown={handleEnter}
                                        className={`${style.box_markup} ${style.digi}`}>
                                        <span>
                                            {values.digi}
                                        </span>
                                        <motion.span
                                            whileHover={{scale: 1.1}}
                                            whileTap={{scale: 0.9}}
                                            className={`${style.send} bx bxs-send`}></motion.span>
                                    </motion.span>
                                </span>
                            </span>
                            </motion.span>
                        }
                    </AnimatePresence>
                </motion.span>
            }
        </AnimatePresence>
    )
}