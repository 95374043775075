import React from "react";
import Status from "./Status";
import CardButtons from "./CardButtons";
// import TransactionInfo from "./Transactions/TransactionInfo";
import LogsButtons from "./LogsButtons";
import MoreButton from "./Buttons/settingsMoreButton";

function Control({modal, setModal}) {
    return (
        <main>
            <div className="dashboard"
            >
                <div className="dash_row">
                    <Status/>
                    <CardButtons modal={modal} setModal={setModal}/>
                </div>
                <div className="dash_row">
                    <MoreButton
                        modal={modal} setModal={setModal}
                    />
                </div>
                <div className="dash_row">
                    <LogsButtons modal={modal} setModal={setModal}/>
                </div>
                {/*<div className="dash_row">*/}
                {/*    <TransactionInfo/>*/}
                {/*</div>*/}
            </div>

        </main>
    );
}

export default Control;