import style from '../components/components.module.css'
import {motion, AnimatePresence} from "framer-motion";
import {useCallback, useEffect, useState} from "react";

export const Markup = ({key, item, digiId, isVisible}) => {
    const [values, setValues] = useState({
        markup: item.markups[digiId].markup,
        title: item.markups[digiId].title,
        digi: digiId
    });

    const handleChange = useCallback((event) => {
        const { name } = event.target.dataset;
        const value = event.target.innerText; // Получаем текстовое содержимое span
        setValues(prevValues => ({
            ...prevValues,
            [name]: value
        }));
    }, []);

    const handleEnter = useCallback((e) => {
        if (e.key === 'Enter') {
            e.preventDefault();
            e.target.blur();
        }
    }, []);

    useEffect(() => {
        if (values.markup !== item.markups[digiId].markup || values.title !== item.markups[digiId].title || values.digi !== digiId) {
            // Логика при изменении значений
            console.log(`Markup: ${values.markup}, Title: ${values.title}, Digi: ${values.digi}`);
        }
    }, [values]);

    return(
        <span
            className={style.main_markup}
            key={key}>
            <span
                className={style.row_markup}>
                <motion.span
                    whileTap={{scale: 0.96}}
                    whileHover={{scale: 1.06}}
                    whileFocus={{borderColor: 'var(--white-color)'}}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    data-name="markup"
                    onBlur={handleChange}
                    onKeyDown={handleEnter}
                    className={`${style.box_markup} ${style.markup}`}>
                    {values.markup}
                </motion.span>
                <motion.span
                    className={style.box_markup}
                    whileTap={{scale: 0.96}}
                    whileHover={{scale: 1.06}}
                    whileFocus={{borderColor: 'var(--white-color)'}}
                    contentEditable={true}
                    suppressContentEditableWarning={true}
                    data-name="title"
                    onBlur={handleChange}
                    onKeyDown={handleEnter}>
                    {values.title}
                </motion.span>
            </span>
            <AnimatePresence mode={`wait`}>
                {isVisible &&
                    <motion.span
                        exit={{height: 0, opacity: 0}}
                        initial={{height: 0, opacity: 0}}
                        animate={{height: '100%', opacity: 1}}
                        style={{overflow: "hidden"}}
                        className={style.row_markup}>
                        <motion.span
                            whileTap={{scale: 0.96}}
                            whileHover={{scale: 1.01}}
                            whileFocus={{borderColor: 'var(--white-color)'}}
                            contentEditable={true}
                            suppressContentEditableWarning={true}
                            data-name="digi"
                            onBlur={handleChange}
                            onKeyDown={handleEnter}
                            className={`${style.box_markup} ${style.digi}`}>
                            <span>{values.digi}</span>
                            <span className={`${style.delete} bx bx-x`}></span>
                        </motion.span>
                    </motion.span>
                }
            </AnimatePresence>
        </span>
    )
};