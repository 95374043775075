import React, { useState, useEffect } from "react";
import Transactions from "./Transactions";
import TransactionsQiwi from "./TransactionsQiwi";
import ServerLink from "../ServerLink";
import {getToServer} from "../ToServer/getToServer";
import {motion} from "framer-motion";
import {TransactionTab} from "./transactionTab";
import {PaymentTab} from "./paymentTab";

function TransactionsTabs() {
    const [activeTab, setActiveTab] = useState("games");
    const [walletData, setWalletData] = useState({ data: [], isLoading: true, error: null });
    const [tableData, setTableData] = useState({ data: [], isLoading: true, error: null });
    const [tableWalletData, setTableWalletData] = useState({ data: [], isLoading: true, error: null });
    const [showAll, setShowAll] = useState(false);
    const [isAnimation, setIsAnimation] = useState({
        games: false,
        qiwi: false,
    });
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedIds, setSelectedIds] = useState({});


    const handleTabClick = (tab) => {
        setActiveTab(tab);
        setStartDate(null);
        setEndDate(null);
        if (tab === "games" && activeTab !== "games") {
            // Загрузка данных для транзакций по играм
            setSelectedIds({});
            setShowAll(false)
            // getToServer('transactions', ServerLink().TableUrl, 'None', setTableData);
        } else if (tab === "qiwi" && activeTab !== "qiwi") {
            // Загрузка данных для пополнений Steam
            setSelectedIds({});
            setShowAll(false)
            getToServer('trnServices', ServerLink().serviceListUrl, 'None', setTableWalletData);
        }
    };
    const handleRefresh = () => {
        setIsAnimation(prev => ({ ...prev, [activeTab]: true }));
        setStartDate(null);
        setEndDate(null);
        setSelectedIds({});
        const callback = () => setIsAnimation(prev => ({ ...prev, [activeTab]: false }));

        if (activeTab === "games") {
            // getToServer('transactions', ServerLink().TableUrl, 'None', setTableData, callback, 'noSilent');
        } else if (activeTab === "qiwi") {
            getToServer('trnServices', ServerLink().serviceListUrl, 'None', setTableWalletData, callback, 'noSilent');
            getToServer('walletBalance', ServerLink().serviceListUrl, 'None', setWalletData);
        }
    };

    const handleSilentRefresh = () => {
        setSelectedIds({});
        if (activeTab === "games") {
            // getToServer('transactions', ServerLink().TableUrl, 'None', setTableData);
        } else if (activeTab === "qiwi") {
            getToServer('trnServices', ServerLink().serviceListUrl, 'None', setTableWalletData, () => ({}), 'silent');
        }
    };

    useEffect(() => {
        getToServer('walletBalance', ServerLink().serviceListUrl, 'None', setWalletData);
        // getToServer('transactions', ServerLink().TableUrl, 'None', setTableData);
    }, []);

    const filterTransactions = (tableData) => {
        if (!startDate || !endDate) {
            return tableData;
        }

        const start = new Date(startDate.setHours(0, 0, 0, 0));
        const end = new Date(endDate.setHours(23, 59, 59, 999));

        return tableData.filter((item) => {
            const itemDateStr = item.date.split(' ')[0];
            const [day, month, year] = itemDateStr.split('.').map(Number);
            const itemDate = new Date(year, month - 1, day);

            return itemDate >= start && itemDate <= end;
        });
    };

    // Обработчик изменений чекбоксов
    const handleCheckboxChange = (id, isChecked) => {
        setSelectedIds(prev => {
            const updatedSelection = { ...prev };
            if (isChecked) {
                updatedSelection[id] = true;
            } else {
                delete updatedSelection[id];
            }
            return updatedSelection;
        });
    };

    // Обработчик выбора всех элементов
    const handleSelectAll = (isChecked, currentTableData) => {
        if (isChecked) {
            const newSelectedIds = {};
            currentTableData.forEach(item => {
                newSelectedIds[item.id] = true;
            });
            setSelectedIds(newSelectedIds);
        } else {
            setSelectedIds({});
        }
    };



    return (
        <>
            <div className="tabs_row">
                <TransactionTab
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    handleRefresh={handleRefresh}
                    isAnimation={isAnimation.games}
                    tableData={tableData}
                    selectedIds={selectedIds}
                />
                <PaymentTab
                    handleTabClick={handleTabClick}
                    activeTab={activeTab}
                    handleRefresh={handleRefresh}
                    isAnimation={isAnimation.qiwi}
                    walletData={walletData}
                    selectedIds={selectedIds}
                />

            </div>
            {activeTab === "games" ?
                <Transactions
                    showAll={showAll}
                    setShowAll={setShowAll}
                    tableData={filterTransactions(tableData.data)}
                    isLoading={tableData.isLoading}
                    handleSilentRefresh={handleSilentRefresh}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectAll={(isChecked) => handleSelectAll(isChecked, filterTransactions(tableData.data))}
                /> :
                <TransactionsQiwi
                    showAll={showAll}
                    setShowAll={setShowAll}
                    tableData={filterTransactions(tableWalletData.data)}
                    isLoading={tableWalletData.isLoading}
                    handleSilentRefresh={handleSilentRefresh}
                    startDate={startDate}
                    endDate={endDate}
                    setStartDate={setStartDate}
                    setEndDate={setEndDate}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    handleCheckboxChange={handleCheckboxChange}
                    handleSelectAll={(isChecked) => handleSelectAll(isChecked, filterTransactions(tableWalletData.data))}
                />}
        </>
    );
}

export default TransactionsTabs;
