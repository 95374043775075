const Footer = () => {

    return(
        <footer>
            <div className="footer">
                {/*v1.3.4 © 2023-2024 bikskey.ru. All Rights Reserved.*/}
                © 2023-2024 oson.ggsel.com. All Rights Reserved. v1.3.8
            </div>
        </footer>
    );
}

export default Footer;