// import Recommendations from "../Components/Recommendations";
import QiwiCard from "../Components/QiwiCard";
import QiwiStatusCard from "../Components/QiwiStatusCard";
import AnimatedPage from "./animatedPage";


function Qiwi () {

    return(
        <AnimatedPage>
            <main>
                <div
                    className="main_container qiwi">
                    <div className="main_gift">
                        <div className="main_row">
                            <QiwiCard/>
                            <QiwiStatusCard/>
                        </div>
                    </div>
                </div>
                {/*<Recommendations/>*/}
            </main>
        </AnimatedPage>
    )
}

export default Qiwi;