import React, { useState, useEffect } from "react";
import ServerLink from "../ServerLink";
import Loader from "../Loader";
import {sendToServer} from "../ToServer/sendToServer";
import {getToServer} from "../ToServer/getToServer";

function GamesHeader() {
    const [isComponentVisible, setComponentVisible] = useState(false);
    const [isAnimationActive, setAnimationActive] = useState(false);
    const [gameFields, setGameFields] = useState([
        {
            digiId: "",
            markup: "",
            sellingPrice: "",
            title: ""
        }
    ]);
    const [tableData, setTableData] = useState({ data: [], isLoading: true, error: null });
    const [selectedAccount, setSelectedAccount] = useState("");
    const [isButtonDisabled, setButtonDisabled] = useState(false);


    useEffect(() => {
        if (isComponentVisible !== true){
            return;
        }
        getToServer('accounts', ServerLink().TableUrl, 'None', setTableData);
    }, [isComponentVisible]);

    const [appUrl, setAppUrl] = useState("");
    // const [accountId, setAccountId] = useState("");
    const [name, setName] = useState("");
    const toggleComponent = () => {
        setComponentVisible(!isComponentVisible);
    };
    const handleFieldChange = (index, field, value) => {
        const newGameFields = [...gameFields];
        newGameFields[index][field] = value;
        setGameFields(newGameFields);
    };
    const handleGamePrices = () => {
        const isConfirmed = window.confirm('Вы уверены, что хотите запустить парс цен?');
        if (!isConfirmed) {
            return;
        }
        sendToServer('updateGamePrice', ServerLink().BufferUrl, 'Null', () => setButtonDisabled(true));
    };


    const handleAddField = () => {
        setGameFields([...gameFields, { digiId: "", markup: "", sellingPrice: "", title: "" }]);
    };

    const handleRemoveField = (index) => {
        const newGameFields = gameFields.filter((_, i) => i !== index);
        setGameFields(newGameFields);
    };

    const handleClearForm = () => {
            setButtonDisabled(true);
            setAnimationActive(true);
            setAppUrl("");
            setSelectedAccount("");
            setName("");
            setGameFields([{ digiId: "", markup: "", sellingPrice: "", title: "" }]);
            setTimeout(() => {
                setAnimationActive(false);
                setButtonDisabled(false);
            }, 2000);
    }

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = {
            appUrl: appUrl,
            accountId: selectedAccount,
            name: name,
            digiIDs: gameFields
        };
        sendToServer('appAdd', ServerLink().EditGameUrl, data, () => handleClearForm());

    };

    return(
        <>
            <span>Игры</span>
            <span><button onClick={toggleComponent} className={`btnDataAdd bx ${isComponentVisible ? 'bx-minus' : 'bx-plus'}`}></button></span>
            <span><button disabled={isButtonDisabled} onClick={handleGamePrices} className={`btnDataAdd btnPrices ${isButtonDisabled ? 'disabled' : ''}`}>Парс цен</button></span>
            {isComponentVisible &&(
                <>
                    {tableData.isLoading ? (
                        <Loader/>
                    ) : (
                        <div className="headerAddContent">
                            <form onSubmit={handleSubmit}>
                                <div className="headerFormRow">
                                    <input className={`${isAnimationActive ? 'animation' : ''}`} required name={`appUrl`} placeholder="URL игры" type="text" value={appUrl} onChange={(e) => setAppUrl(e.target.value)} />
                                    <select className={`${isAnimationActive ? 'animation' : ''}`} required name="accountId" value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>
                                        <option value="" disabled>Выберите аккаунт</option>
                                        {tableData.data.map(account => (
                                            <option key={account.id} value={account.id}>
                                                {account.login} | {account.balance} | {account.region} {/* Здесь вы можете использовать любую информацию из аккаунта */}
                                            </option>
                                        ))}
                                    </select>                            <input className={`${isAnimationActive ? 'animation' : ''}`} required name="name" placeholder="Название игры" type="text" value={name} onChange={(e) => setName(e.target.value)} />
                                    <button type="submit" disabled={isButtonDisabled} className={`headerFormButton bx bx-send ${isButtonDisabled ? 'disabled' : ''}`}></button>

                                </div>
                                {gameFields.map((game, index) => (
                                    <div key={index} className="headerFormRow">
                                        <input className={`${isAnimationActive ? 'animation' : ''}`}
                                            value={game.digiId}
                                            onChange={(e) => handleFieldChange(index, "digiId", e.target.value)}
                                            name="digiId"
                                            placeholder={`ID Digiseller ${index + 1}`}
                                            type="text"
                                        />
                                        <input className={`${isAnimationActive ? 'animation' : ''}`}
                                            value={game.markup}
                                            onChange={(e) => handleFieldChange(index, "markup", e.target.value)}
                                            name="markup"
                                            placeholder={`Наценка ${index + 1} в %`}
                                            type="text"
                                        />
                                        <input className={`${isAnimationActive ? 'animation' : ''}`}
                                            value={game.sellingPrice}
                                            onChange={(e) => handleFieldChange(index, "sellingPrice", e.target.value)}
                                            name="sellingPrice"
                                            placeholder={`Цена продажи ${index + 1} в руб.`}
                                            type="text"
                                        />
                                        <input className={`${isAnimationActive ? 'animation' : ''}`}
                                            value={game.title}
                                            onChange={(e) => handleFieldChange(index, "title", e.target.value)}
                                            name="digiTitle"
                                            placeholder={`Описание ${index + 1}. Например GGSell`}
                                            type="text"
                                        />
                                        <button className="bx bx-minus headerFormButton" type="button" onClick={() => handleRemoveField(index)}></button>
                                    </div>
                                ))}
                                <button className={`bx bx-chevron-down-circle headerFormButton`} type="button" onClick={handleAddField}></button>
                            </form>
                        </div>
                        )}
                </>
            )}
        </>
    )
}

export default GamesHeader;