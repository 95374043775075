import {useState} from "react";
import style from "./components.module.css"
import {motion, AnimatePresence} from "framer-motion";

export const RowCount = ({setMaxValue, maxValue}) => {
    const [isVisible, setVisible] = useState(false);

    const handleVisible = () => {
        setVisible(!isVisible);
    }
    const handleMaxEdit = (event) => {
        setMaxValue(event.target.innerText);
    };
    const handleKeyDown = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            event.target.blur();
        }
    };
    return(
        <span
            className={style.filter_box_button}>
                <span
                    onClick={handleVisible}
                    className={`bx bx-filter ${style.filter}`}></span>
                <AnimatePresence>
                    {isVisible &&
                        <motion.span
                            exit={{width: 0, opacity: 0}}
                            initial={{width: 0, opacity: 0}}
                            animate={{width: 'auto', opacity: 1}}
                            style={{overflow: "hidden"}}
                        >
                            <motion.span
                                suppressContentEditableWarning
                                contentEditable
                                onKeyDown={handleKeyDown}
                                onBlur={handleMaxEdit}
                                className={style.max_value}
                                whileFocus={{'border': '1px solid #fff'}}>
                                {maxValue}
                            </motion.span>
                        </motion.span>
                    }
                </AnimatePresence>
            </span>
    )
}