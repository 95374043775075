import React, {useEffect, useState} from "react";
import ServerLink from "../ServerLink";
import {motion} from "framer-motion";
import {getToServer} from "../ToServer/getToServer";
import {sendToServer} from "../ToServer/sendToServer";

function TransactionsHeader() {
    const [isComponentVisible, setComponentVisible] = useState(false);
    const [isAnimationActive, setAnimationActive] = useState(false);
    const [isNewComponentVisible, setNewComponentVisible] = useState(false);
    const [accounts, setAccounts] = useState({ data: [], isLoading: true, error: null });
    const [selectedAccount, setSelectedAccount] = useState("");
    const [games, setGames] = useState({ data: [], isLoading: true, error: null });
    const [selectedGame, setSelectedGame] = useState("");
    const [isChecked, setIsChecked] = useState(false);
    const [selectedLogin, setSelectedLogin] = useState('');

    const toggleComponent = () => {
        setComponentVisible(!isComponentVisible);
    };
    const toggleNewComponent = () => {
        setNewComponentVisible(!isNewComponentVisible);
    };
    const handleSubmit = (event) => {
        event.preventDefault();
        const formDataObject = new FormData(event.target);
        const data = {};

        formDataObject.forEach((value, key) => {
            data[key] = value;
        });

        sendToServer('transactionAdd', ServerLink().EditGameUrl, data, () => {
            setAnimationActive(true);
            event.target.reset();
            setTimeout(() => setAnimationActive(false), 2000);
        });
    };

    const handleSubmitWallet = (event) => {
        event.preventDefault();
        const formDataObject = new FormData(event.target);
        const data = {};

        formDataObject.forEach((value, key) => {
            data[key] = value;
        });
        console.log(data);

        sendToServer('transactionAddWallet', ServerLink().EditGameUrl, data, () => {
            setAnimationActive(true);
            event.target.reset();
            setTimeout(() => setAnimationActive(false), 2000);
        });
    };

    // useEffect(() => {
    //     getToServer('accounts', ServerLink().TableUrl, '', setAccounts, () => {}, 'silent');
    // }, [isComponentVisible]);
    // useEffect(() => {
    //     getToServer('digiGames', ServerLink().TableUrl, '', setGames, () => {}, 'silent');
    // }, [isComponentVisible]);

    const handleChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleAccountChange = (e) => {
        const selectedIndex = e.target.selectedIndex - 1; // Вычитаем 1, т.к. первый option disabled
        const selectedLogin = accounts.data[selectedIndex].login; // Получаем логин из данных аккаунтов
        setSelectedAccount(e.target.value);
        setSelectedLogin(selectedLogin); // Обновляем состояние выбранного логина
    };

    return(
        <span className={'headerModalAndButton'}>
            <span>Транзакции</span>
            {/*<span className="headerButton">*/}
            {/*    <motion.button*/}
            {/*        whileHover={{ scale: 0.96, backgroundColor: '#ffffff', color: '#000000' }}*/}
            {/*        whileTap={{scale: 0.85}}*/}
            {/*        onClick={toggleComponent}*/}
            {/*        className={`btnDataAdd bx ${isComponentVisible ? 'bx-minus' : 'bx-plus'}`}>*/}
            {/*    </motion.button>*/}
            {/*    <motion.button*/}
            {/*        whileHover={{ scale: 0.96 }}*/}
            {/*        whileTap={{scale: 0.85}}*/}
            {/*        onClick={toggleNewComponent}*/}
            {/*        className={`btnDataAddWallet bx ${isNewComponentVisible ? 'bxs-wallet' : 'bx-wallet'}`}>*/}
            {/*    </motion.button>*/}
            {/*</span>*/}
            {/*{isComponentVisible && (*/}
            {/*    <div className="headerAddContent">*/}
            {/*        <form onSubmit={handleSubmit} key='addTransaction'>*/}
            {/*            <div className="headerFormRow">*/}
            {/*                <input className={`${isAnimationActive ? 'animation' : ''}`} required name="code" placeholder="Уникальный код" type="text"/>*/}
            {/*                <input className={`${isAnimationActive ? 'animation' : ''}`} required name="steamUrl" placeholder="Ссылка на steam" type="text"/>*/}
            {/*                <select className={`${isAnimationActive ? 'animation' : ''}`} required name="accountId" value={selectedAccount} onChange={(e) => setSelectedAccount(e.target.value)}>*/}
            {/*                    <option value="" disabled>Выберите аккаунт</option>*/}
            {/*                    {accounts.data.map(account => (*/}
            {/*                        <option key={account.id} value={account.id}>*/}
            {/*                            {account.login} | {account.balance} | {account.region}*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*                <select className={`${isAnimationActive ? 'animation' : ''}`} required name="gameId" value={selectedGame} onChange={(e) => setSelectedGame(e.target.value)}>*/}
            {/*                    <option value="" disabled>Выберите игру</option>*/}
            {/*                    {games.data.map(game => (*/}
            {/*                        <option key={game.id} value={game.id}>*/}
            {/*                            {game.gId} | {game.name} /!* Используйте game.name вместо game.id *!/*/}
            {/*                        </option>*/}
            {/*                    ))}*/}
            {/*                </select>*/}
            {/*            /!*</div>*!/*/}
            {/*            /!*<div className="headerFormRow">*!/*/}

            {/*                <button type="submit" className="headerFormButton bx bx-send"></button>*/}
            {/*            </div>*/}
            {/*        </form>*/}
            {/*    </div>*/}
            {/*)}*/}
            {/*{isNewComponentVisible && (*/}
            {/*    <div className="headerAddContent">*/}
            {/*        <input*/}
            {/*            type="checkbox"*/}
            {/*            checked={isChecked}*/}
            {/*            onChange={handleChange}*/}
            {/*            className='checkboxHeaderTable'*/}
            {/*        />*/}
            {/*        {isChecked ? (*/}
            {/*            <form onSubmit={handleSubmitWallet} key='selfPayment'>*/}
            {/*                <div className="headerFormRow">*/}
            {/*                    <input required name="code" placeholder="Уникальный код" type="text"/>*/}
            {/*                    <select className={`${isAnimationActive ? 'animation' : ''}`} required name="accountId"*/}
            {/*                            value={selectedAccount} onChange={handleAccountChange}>*/}
            {/*                        <option value="" disabled>Выберите аккаунт</option>*/}
            {/*                        {accounts.data.map(account => (*/}
            {/*                            <option key={account.id} value={account.id}>*/}
            {/*                                {account.login} | {account.balance} | {account.region}*/}
            {/*                            </option>*/}
            {/*                        ))}*/}
            {/*                    </select>*/}
            {/*                    <input type="hidden" name="login" value={selectedLogin || ''}/>*/}
            {/*                    <input required name="amount" placeholder="Сумма в рублях" type="text"/>*/}
            {/*                    <button type="submit" className="headerFormButton bx bx-send"></button>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        ) : (*/}
            {/*            <form onSubmit={handleSubmitWallet} key='payment'>*/}
            {/*                <div className="headerFormRow">*/}
            {/*                    <input required name="code" placeholder="Уникальный код" type="text"/>*/}
            {/*                    <input required name="login" placeholder="Логин Steam" type="text"/>*/}
            {/*                    <input required name="amount" placeholder="Сумма в рублях" type="text"/>*/}
            {/*                    <button type="submit" className="headerFormButton bx bx-send"></button>*/}
            {/*                </div>*/}
            {/*            </form>*/}
            {/*        )}*/}
            {/*    </div>*/}
            {/*)}*/}
        </span>
    )
}

export default TransactionsHeader;