import React, { useState, useEffect } from 'react';
import Check from "../img/Check.svg";
import Processing from "../img/processing.svg";
import Timer from "./Timer";

function ProgressStatusCard({ InfoData }) {
    const [currentStep, setCurrentStep] = useState(0);
    const [showTimer, setShowTimer] = useState(false);

    const steps = [
        "Ваша заявка поступила в обработку",
        "Примите заявку в друзья от курьера",
        "Передаем Вам игру",
        "Игра отправлена на Ваш аккаунт",
    ];

    // Update the completion status based on the current InfoData status
    useEffect(() => {
        if (InfoData.status === "Ваша заявка поступила в обработку") {
            setCurrentStep(0);
            setShowTimer(false);
        } else if (InfoData.status === "Примите заявку в друзья от курьера") {
            setCurrentStep(1);
            setShowTimer(true);
        } else if (InfoData.status === "Передаем Вам игру") {
            setCurrentStep(2);
            setShowTimer(false);
        } else if (InfoData.status === "Игра отправлена на Ваш аккаунт!") {
            setCurrentStep(3);
            setShowTimer(false);
        }
    }, [InfoData]);

    return (
        <div className="progress_status">
            {steps.map((step, index) => (
                <div className="progress_row" key={index}>
                    <div className="progress_image">
                        <div className={"progress_circle " + (currentStep === 3 ? "completed" : (index < currentStep ? "completed" : (index === currentStep ? "processing" : "")))}>
                            {(index < currentStep || currentStep === 3) && <img className="check" src={Check} alt="" />}
                            {index === currentStep && <img className="processing" src={Processing} alt="" />}
                        </div>
                    </div>
                    <div className="progress_title">
                        {step}
                        {index === 1 && showTimer && <Timer initialTime={240} />}
                    </div>
                </div>
            ))}
        </div>
    );
}

export default ProgressStatusCard;
