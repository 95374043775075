import {motion} from "framer-motion";
import AnimatedPage from "./animatedPage";
function Info(){

    const handleClick = () => {
        window.open('https://t.me/dochto', '_blank')
    }

    return(
        <AnimatedPage>
            <main>
                <div
                    className="main_wrapper">
                    <div className="main_card">
                        <div className="card">
                            <div className="main_card">
                                <div className="card_row">
                                    <div className="main_img">
                                        <i className='bx bx-message-alt-detail'></i>
                                    </div>
                                </div>
                                <div className="card_row">
                                    <span className="main_text">
                                        <span className="infoText">
                                            Сообщить о технической проблеме можно в Telegram!
                                        </span>
                                        <motion.button
                                            whileHover={{scale: 0.98}}
                                            whileTap={{scale: 0.96}}
                                            onClick={handleClick}
                                        >
                                            <i className='bx bxl-telegram'></i>
                                            <span>
                                                Telegram
                                            </span>
                                        </motion.button>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AnimatedPage>
    );
}

export default Info;