import React, {useState} from 'react';
import { motion } from 'framer-motion';
import {AccountControl} from "./accountControl";
import {ReactComponent as TurnDown} from "../../img/arrow.turn.down.right.svg";


const AccountNode = ({ account, draggedAccountId, onDragStart, onDragEnd, onDrop, key }) => {
    const [dragOverId, setDragOverId] = useState(null);

    const handleDragStart = () => {
        onDragStart(account.accountId);
    };

    const handleDragEnd = (e) => {
        onDragEnd();
        setDragOverId(null);
    };

    const handleDragOver = (e) => {
        e.preventDefault();
        if (dragOverId !== account.accountId) {
            setDragOverId(account.accountId);
        }
    };

    const handleDrop = () => {
        onDrop(account.accountId);
        setDragOverId(null);
    };

    const handleDragLeave = () => {
        setDragOverId(null);
    };

    const variants = {
        initial: { opacity: 1, scale: 1 },
        dragging: { opacity: 0.5, scale: 1 },
        draggedOver: { opacity: 0.8, scale: 1.02 },
        dropped: { opacity: 1, scale: 1 }
    };

    return (
        <motion.div
            className={`account_row`}
        >
            <motion.div
                draggable
                onDragStart={handleDragStart}
                onDragLeave={handleDragLeave}
                onDragEnd={handleDragEnd}
                onDragOver={handleDragOver}
                onDrop={handleDrop}
                className={`account_box ${draggedAccountId === account.accountId ? 'dragging' : ''}`}
                initial="initial"
                animate={
                    draggedAccountId === account.accountId
                        ? "dragging"
                        : dragOverId === account.accountId
                            ? "draggedOver"
                            : "dropped"
                }
                variants={variants}
            >
                <div className="border_account">
                    <TurnDown/>
                </div>
                <div className="account_info">
                    <span
                        className="account_region"
                    >{account.region}</span>
                    <div
                        className="account_group_info"
                    >
                        <span>{account.steam_login}</span>
                        <span>{account.balance}</span>
                    </div>
                </div>
                <AccountControl/>
            </motion.div>

            {account.children.length > 0 && (
                <div className="children">
                    {account.children.map((child) => (
                        <AccountNode
                            key={child.accountId}
                            account={child}
                            draggedAccountId={draggedAccountId}
                            onDragStart={onDragStart}
                            onDragEnd={onDragEnd}
                            onDrop={onDrop}
                        />
                    ))}
                </div>
            )}
        </motion.div>
    );
};

export default AccountNode;
