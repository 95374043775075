import React, { useState, useRef, useEffect } from 'react';
import Loader from "./Loader";


const Carousel = ({ games, loading }) => {
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(null);
    const [scrollLeft, setScrollLeft] = useState(0);
    const carouselRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - carouselRef.current.offsetLeft);
        setScrollLeft(carouselRef.current.scrollLeft);
    };

    const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - carouselRef.current.offsetLeft;
        const walk = (x - startX) * 2; // You can adjust the sensitivity here
        carouselRef.current.scrollLeft = scrollLeft - walk;
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    useEffect(() => {
        // Функция для автоматического пролистывания
        const autoScroll = () => {
            const nextIndex = (games.length + 1) % games.length; // Циклическое пролистывание
            carouselRef.current.scrollLeft = nextIndex * carouselRef.current.clientWidth;
        };

        // Запускаем автоматическое пролистывание каждые 10 секунд
        const intervalId = setInterval(autoScroll, 10000);

        // Очищаем интервал при размонтировании компонента
        return () => {
            clearInterval(intervalId);
        };
    }, [games]);

    const handleLeftClick = () => {
        // Прокрутка влево
        carouselRef.current.scrollLeft -= 300; // Измените эту величину по вашему усмотрению
    };

    const handleRightClick = () => {
        // Прокрутка вправо
        carouselRef.current.scrollLeft += 300; // Измените эту величину по вашему усмотрению
    };
    return (
        <div className="wrapper">
            <div className="left" onClick={handleLeftClick}>
                <span>
                    <i className='bx bx-chevron-left'></i>
                </span>
            </div>
            <div className="wrapper_title">
                <span>Ваши персональные рекомендации</span>
            </div>
            <div
                ref={carouselRef}
                className={`carousel ${isDragging ? 'dragging' : ''}`}
                onMouseDown={handleMouseDown}
                onMouseMove={handleMouseMove}
                onMouseUp={handleMouseUp}
                onMouseLeave={handleMouseUp}
            >
                {loading ? (
                    <>
                        <div className="carousel_card">
                            <div className="carousel_img">
                                <Loader/>
                            </div>
                            <div className="carousel_desc">
                                <div className="carousel_title">
                                    Loading...
                                </div>
                                <div className="carousel_info">
                                    <div className="carousel_price">
                                        <span>Лучшая цена</span>
                                        <span className="circle"></span>
                                        <span className="price">Loading...</span>
                                    </div>
                                    <div className="carousel_buy">
                                        <button className="button_buy">Купить</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </>
                ):
                    <>
                        {games.map((game, index) => (
                            <div className="carousel_card" key={index}>
                                <div className="carousel_img">
                                    <img className="img_blur" src={game.imgUrl} alt={`img-${index}`} draggable="false" />
                                    <img src={game.imgUrl} alt={`img-${index}`} draggable="false" />
                                </div>
                                <div className="carousel_desc">
                                    <div className="carousel_title">
                                        {game.name}
                                    </div>
                                    <div className="carousel_info">
                                        <div className="carousel_price">
                                            <span>Лучшая цена</span>
                                            <span className="circle"></span>
                                            <span className="price">{game.price} RUB</span>
                                        </div>
                                        <div className="carousel_buy">
                                            <a rel="noreferrer" target={"_blank"} href={game.digiUrl} className="button_buy">Купить</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </>
                }
            </div>
            <div className="right" onClick={handleRightClick}>
                <span>
                    <i className='bx bx-chevron-right'></i>
                </span>
            </div>
        </div>
    );
};

export default Carousel;
