import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
function SearchResult({ infoData, searchRef }) {
    useEffect(() => {
        console.log('infoData.link changed:', infoData.link);
    }, [infoData.link]);

    return (
        <div className="searchResult" ref={searchRef}>
            <div className="searchRow">
        <span>
          <Link to={infoData.link}>Статус: {infoData.status}</Link>
        </span>
            </div>
        </div>
    );
}

export default SearchResult;
