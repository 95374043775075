import {useState} from "react";
import style from "./components.module.css"
import {motion, AnimatePresence} from "framer-motion";

export const AppUrl = ({url, isUrlVisible}) => {
    const [isVisible, setVisible] = useState(false);
    const handleVisible = () => {
      setVisible(!isVisible);
    }

    return(
        <AnimatePresence>
            <span>
                <motion.span
                    onClick={handleVisible}
                    exit={{width: 0}}
                    initial={{width: '150px'}}
                    animate={{ width: isVisible || isUrlVisible ? 'auto' : '150px' }}
                    className={style.url_hidden}>
                    {url}
                </motion.span>
            </span>
        </AnimatePresence>
    )
}