import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App/App';
import {BrowserRouter} from "react-router-dom";
import {AuthProvider} from "./Components/AuthProvider";
import {ErrorProvider} from "./Components/Error/errorContext";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
      <AuthProvider>
          <BrowserRouter>
              <ErrorProvider>
                  <App />
              </ErrorProvider>
          </BrowserRouter>
      </AuthProvider>
);