import Header from "../Components/Header";
import Footer from "../Components/Footer";
import Main from "../Pages/main";
import Admin from "../Pages/admin";
import Info from "../Pages/info";
import Other from "../Pages/other";
import Gift from "../Pages/gift";
import Qiwi from "../Pages/qiwi";
import "./App.css";
import {AnimatePresence} from "framer-motion";
import {
    Routes,
    Route, useLocation
} from 'react-router-dom';
import {useModalConfig} from "../Components/Modals/modalConfig";
import ModalContent from "../Components/Modals/modalContent";
import {ErrorPopup} from "../Components/Error/errorPopup";
import {useError} from "../Components/Error/errorContext";
function App(){
    const {modal, setModal, dragOffset, setDragOffset, modalPositions, setModalPositions, modalSize, setModalSize, modalConfigs} = useModalConfig();
    const { error, handleErrorVision } = useError();
    const location = useLocation();

    return(
        <>
                <Header
                    modal={modal}
                    setModal={setModal}
                ></Header>
                <div className="background_element"></div>
                <AnimatePresence mode={`wait`}>
                    <Routes key={location.pathname} location={location}>
                        <Route exact path="/" element={<Main/>} />
                        <Route exact path="/admin" element={<Admin
                            modal={modal}
                            setModal={setModal}
                        />} />
                        <Route exact path="/info" element={<Info/>} />
                        <Route exact path="/gift" element={<Gift/>} />
                        <Route exact path="/payment" element={<Qiwi/>} />
                        <Route exact path="*" element={<Other/>} />
                    </Routes>
                </AnimatePresence>
                <Footer/>
            <AnimatePresence>
                {error && <ErrorPopup message={error} handleErrorVision={handleErrorVision} />}
            </AnimatePresence>
            <AnimatePresence>
                {modalConfigs.map(({key, title, ContentComponent}) =>
                        modal[key] && (
                            <ModalContent
                                key={key}
                                title={title}
                                isOpened={modal[key]}
                                onClose={() => setModal({...modal, [key]: false})}
                                dragOffset={dragOffset[key]}
                                setDragOffset={(newPosition) => setDragOffset({...dragOffset, [key]: newPosition})}
                                modalPosition={modalPositions[key]}
                                setModalPosition={(newPosition) => setModalPositions({...modalPositions, [key]: newPosition})}
                                modalSize={modalSize[key]}
                                setModalSize={(newSize) => setModalSize({...modalSize, [key]: newSize})}
                            >
                                <ContentComponent/>
                            </ModalContent>
                        )
                )}
            </AnimatePresence>
        </>
    );
}

export default App;