import Carousel from "./Carousel";
import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";


function Recommendations () {
    const [games, setGames] = useState([]);
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
        sendToServer();
    }, []);
    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        // Data to send to the server (in this case, we send it as JSON)
        const data = {
            object: 'Recommendations'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the server response and update the gameData
                console.log(data.table)
                setGames(data.table); // Сохраняем данные в состоянии
                setIsLoading(false)
            })
            .catch((error) => {
                console.error('Error sending uniquecode to the server:', error);
            });
    };

    return(
        <Carousel games={games} loading={isLoading} />
    )
}

export default Recommendations;