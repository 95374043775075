import React, { useState, useEffect } from 'react';
import ServerLink from "../ServerLink";
import Loader from "../Loader";

function Proxy() {
    const [tableData, setTableData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [changedProxyFields, setChangedProxyFields] = useState({});
    const [changedTitleFields, setChangedTitleFields] = useState({});

    useEffect(() => {
        sendToServer();
    }, []);

    const sendToServer = () => {
        const apiUrl = ServerLink().TableUrl;

        const requestData = {
            object: 'proxy',
            data: ''
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                setTableData(responseData.table);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    // Функция для обновления поля proxy на сервере
    const updateProxy = (id, newProxy) => {
        // Проверяем, были ли внесены изменения
        const updatedData = [...tableData];
        const itemIndex = updatedData.findIndex((item) => item.id === id);
        if (itemIndex !== -1 && updatedData[itemIndex].proxy !== newProxy) {
            const apiUrl = ServerLink().EditGameUrl;

            const data = {
                id: id,
                proxy: newProxy
            };

            const requestData = {
                object: 'updateProxy',
                data: data
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(requestData)
            })
                .then((response) => response.json())
                .then((responseData) => {
                    setChangedProxyFields({ ...changedProxyFields, [id]: true });
                    sendToServer();
                    setTimeout(() => {
                        setChangedProxyFields({ ...changedProxyFields, [id]: false });
                    }, 2000);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    // Функция для обновления поля title на сервере
    const updateTitle = (id, newTitle) => {
        // Проверяем, были ли внесены изменения
        const updatedData = [...tableData];
        const itemIndex = updatedData.findIndex((item) => item.id === id);
        if (itemIndex !== -1 && updatedData[itemIndex].title !== newTitle) {
            const apiUrl = ServerLink().EditGameUrl;

            const data = {
                id: id,
                proxyTitle: newTitle
            };

            const requestData = {
                object: 'updateProxyTitle',
                data: data
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(requestData)
            })
                .then((response) => response.json())
                .then((responseData) => {
                    setChangedTitleFields({ ...changedTitleFields, [id]: true });
                    sendToServer();
                    setTimeout(() => {
                        setChangedTitleFields({ ...changedTitleFields, [id]: false });
                    }, 2000);
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    };

    const handleDelete = (id) => {
        const apiUrl = ServerLink().EditGameUrl;

        const data = {
            id: id
        };

        const requestData = {
            object: 'deleteProxy',
            data: data
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                // "Authorization": "Bearer " + localStorage.getItem("token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                sendToServer();
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
            });
    };

    const updateAccount = (proxyId, newAccountId) => {
        // Проверяем, были ли внесены изменения
        const updatedData = [...tableData];
        const itemIndex = updatedData.findIndex((item) => item.id === proxyId);

        if (itemIndex !== -1 && updatedData[itemIndex].accountId !== newAccountId) {
            const apiUrl = ServerLink().EditGameUrl;

            const data = {
                proxyId: proxyId,
                newAccountId: newAccountId,
            };

            const requestData = {
                object: 'updateProxyAccount',
                data: data,
            };

            fetch(apiUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                    // "Authorization": "Bearer " + localStorage.getItem("token")
                },
                body: JSON.stringify(requestData),
            })
                .then((response) => response.json())
                .then((responseData) => {
                    console.log(responseData);
                    // Обновляем таблицу после успешного обновления аккаунта
                    sendToServer();
                })
                .catch((error) => {
                    console.error('Error fetching data:', error);
                });
        }
    };


    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <table>
                    <thead>
                    <tr>
                        <th>ID</th>
                        <th>Прокси</th>
                        <th>Описание</th>
                        <th>Аккаунт</th>
                        <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {tableData.map((row) => (
                        <tr key={row.id}>
                            <td>{row.id}</td>
                            <td>
                            <span
                                className={`editable_cell ${changedProxyFields[row.id] ? 'animation' : ''}`}
                                suppressContentEditableWarning={true}
                                contentEditable="true"
                                onBlur={(e) => updateProxy(row.id, e.target.innerText)}
                            >
                                {row.proxy}
                            </span>
                            </td>
                            <td>
                            <span
                                className={`editable_cell ${changedTitleFields[row.id] ? 'animation' : ''}`}
                                suppressContentEditableWarning={true}
                                contentEditable="true"
                                onBlur={(e) => updateTitle(row.id, e.target.innerText)}
                            >
                                {row.title}
                            </span>
                            </td>
                            <td>
                                <select
                                    value={row.accountId === null ? 'none' : row.accountId}
                                    onChange={(e) => updateAccount(row.id, e.target.value)}
                                >
                                    <option value="none">Выберите аккаунт</option>
                                    {row.accounts.map((account) => (
                                        <option key={account.id} value={account.id}>
                                            {account.steam_login}
                                        </option>
                                    ))}
                                </select>
                            </td>
                            <td>
                                <button onClick={() => handleDelete(row.id)} className="table_delete bx bx-trash"></button>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            )}
        </div>
    );

}

export default Proxy;
