import style from './components.module.css'
import {motion} from "framer-motion";
export const SellingPrice = ({item, key}) => {

    return(
        <motion.span
            key={key}
            whileFocus={{borderBottom: '1px solid var(--white-color)'}}
            whileTap={{scale: 1}}
            contentEditable={true}
            suppressContentEditableWarning={true}
            className={style.sell_price}>
            {item}
        </motion.span>
    )
}