import React from "react";
import Modal from "./modal";
import {AnimatePresence,motion} from "framer-motion";

function ModalContent({ title, isOpened, onClose, children, dragOffset, setDragOffset, modalPosition, setModalPosition, modalSize, setModalSize, key}) {
    const modalVariants = {
        start: {
            opacity: 0,
            transition: {
                duration: 0.3,
                type: 'spring',
                stiffness: 40
            }
        },
        hidden: {
            opacity: 0,
            transition: {
                duration: 0.2
            }
        },
        visible: {
            opacity: 1
        },
    };
    return (
            <motion.div
                key={key}
                initial="start"
                animate="visible"
                exit="hidden"
                variants={modalVariants}
            >
                <Modal
                    Children = {children}
                    Title={title}
                    isOpened={isOpened}
                    onModalClose={onClose}
                    dragOffset={dragOffset}
                    setDragOffset={setDragOffset}
                    modalPosition={modalPosition}
                    setModalPosition={setModalPosition}
                    modalSize={modalSize}
                    setModalSize={setModalSize}
                >
                </Modal>
            </motion.div>
    );
}

export default ModalContent;
