import React, {useState, useEffect} from 'react';
import ServerLink from "../ServerLink";
import Loader from "../Loader";
import AnsiToHtml from 'ansi-to-html';

function UserLogs() {
    const [tableData, setTableData] = useState([]); // Инициализируем tableData как пустой массив
    const [isLoading, setIsLoading] = useState(true);

    const convert = new AnsiToHtml({
        colors: {
            0: '#000000', // black
            1: '#cc0000', // red
            2: '#4e9a06', // green
            3: '#ffe472', // --warning
            4: '#97fa7c', // --info
            5: '#75507b', // magenta
            6: '#06989a', // cyan
            7: '#d3d7cf', // light gray
            8: '#555753', // dark gray
            9: '#ef2929', // light red
            10: '#8ae234', // light green
            11: '#fce94f', // light yellow
            12: '#729fcf', // light blue
            13: '#ad7fa8', // light magenta
            14: '#34e2e2', // light cyan
            15: '#eeeeec'  // white
        }
    });

    const sendToServer = () => {
        const apiUrl = ServerLink().logListUrl;

        const requestData = {
            object: 'userLog'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => response.json())
            .then((responseData) => {
                const convertedData = responseData.data.map(log => convert.toHtml(log)).reverse();
                setTableData(convertedData);
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error fetching data:', error);
                setIsLoading(false);
            });
    };

    useEffect(() => {
        sendToServer();
    }, []);

    return (
        <>{isLoading ? (
            <Loader />
        ) : (
            <table className={`logs_table`}>
                <thead>
                <tr>
                    <th>Непосредственно Лог :)</th>
                </tr>
                </thead>
                <tbody>
                {tableData.map((log, index) => (
                    <tr key={index}>
                        <td dangerouslySetInnerHTML={{__html: log}}></td>
                    </tr>
                ))}
                </tbody>
            </table>
        )}
        </>
    )
}

export default UserLogs;
