import {motion} from "framer-motion";
const CardButtons = ({ modal, setModal }) => {

    return (
        <>
            <div className="card cardMainButton">
                <div className="card_row">
                    <motion.button
                        onClick={() => setModal({ ...modal, transactions: true })}
                        className="btn_transactions"
                        whileHover={{ scale: 0.95 }}
                        whileTap={{ scale: 0.9 }}
                        transition={{ duration: 0.3 }}
                    >
                        <i className='bx bxs-cart'></i>
                    </motion.button>
                    <motion.button
                        // onClick={() => setModal({ ...modal, games: true })}
                        className="btn_games btn_close"
                        // whileHover={{ scale: 0.95 }}
                        // whileTap={{ scale: 0.9 }}
                        // transition={{ duration: 0.3 }}
                    >
                        <i className='bx bxs-joystick'></i>
                    </motion.button>
                    <motion.button
                        // onClick={() => setModal({ ...modal, accounts: true })}
                        className="btn_accounts btn_close"
                        // whileHover={{ scale: 0.95 }}
                        // whileTap={{ scale: 0.9 }}
                        // transition={{ duration: 0.3 }}
                    >
                        <i className='bx bxs-user'></i>
                    </motion.button>
                </div>
            </div>
        </>
    );
};

export default CardButtons;
