import {motion} from "framer-motion";
const LogsButtons = ({ modal, setModal }) => {

    return(
        <div className='card logs_card'>
            <div className="card_row logs_row">
                {/*<motion.button*/}
                {/*    whileHover={{ scale: 0.95 }}*/}
                {/*    whileTap={{ scale: 0.9 }}*/}
                {/*    onClick={() => setModal({ ...modal, proxy: true })} className='btnProxy'>*/}
                {/*    <span>Прокси</span>*/}
                {/*</motion.button>*/}
                <motion.button
                    // whileHover={{ scale: 0.95 }}
                    // whileTap={{ scale: 0.9 }}
                    // onClick={() => setModal({ ...modal, giftLogs: true })}
                    className='btnLogs btn_close'>
                    <span>
                        <i className='bx bxl-steam'></i>
                    </span>
                    <span>Логи выдачи</span>
                </motion.button>
                <motion.button
                    // whileHover={{scale: 0.95}}
                    // whileTap={{scale: 0.9}}
                    // onClick={() => setModal({...modal, parceLogs: true})}
                    className='btnLogsPrice btn_close'>
                    <span>
                        <i className='bx bxl-blogger'></i>
                    </span>
                    <span>Логи парса цен</span>
                </motion.button>
                <motion.button
                    whileHover={{scale: 0.95}}
                    whileTap={{scale: 0.9}}
                    onClick={() => setModal({...modal, adminLogs: true})}
                    className='btnLogsAdmin'>
                    <span>
                        <i className='bx bxs-mouse'></i>
                    </span>
                    <span>Логи действий</span>
                </motion.button>
            </div>
        </div>
    )
}

export default LogsButtons;