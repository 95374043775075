

export const FinancialContent = ({financialData}) => {
    return (
        <div className="table_content">
            <table className="tableFinancial">
                <tbody>
                <tr>
                    <td>Комиссия GGsel + Digi пополнения Steam</td>
                    <td>{financialData.data[0].Commision_GGselDigi_TopUp}</td>
                </tr>
                <tr>
                    <td>Комиссия GGsel + Digi гифт Steam</td>
                    <td>{financialData.data[0].Commision_GGselDigi_Gift}</td>
                </tr>
                <tr>
                    <td>Комиссия Plati + Digi пополнения Steam</td>
                    <td>{financialData.data[0].Commision_PlatiDigi_TopUp}</td>
                </tr>
                <tr>
                    <td>Комиссия Plati + Digi гифт Steam</td>
                    <td>{financialData.data[0].Commision_PlatiDigi_Gift}</td>
                </tr>
                <tr>
                    <td>Стоимость пополнения BroGamers %</td>
                    <td>
                        <span>{financialData.data[0].Extra_TopUp}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Наценка % WM (Digi)</td>
                    <td>
                        <span>{financialData.data[0].Extra_WM}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Наценка % P2P RUB</td>
                    <td>
                        <span>{financialData.data[0].Extra_P2PRUB}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Курс lava для вывода USDT</td>
                    <td>{financialData.data[0].Currency_Lava_PayoutUSDT}</td>
                </tr>
                <tr>
                    <td>Курс freekassa для вывода USDT</td>
                    <td>{financialData.data[0].Currency_FK_PayoutUSDT}</td>
                </tr>
                <tr>
                    <td>Курс paypalych для вывода USDT</td>
                    <td>{financialData.data[0].Currency_PP_PayoutUSDT}</td>
                </tr>
                <tr>
                    <td>Курс gamemoney для вывода USDT</td>
                    <td>{financialData.data[0].Currency_GM_PayoutUSDT}</td>
                </tr>
                <tr>
                    <td>Комиссия платежки Lava %</td>
                    <td>
                        <span>{financialData.data[0].Commision_Lava}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Комиссия платежки Freekassa %</td>
                    <td>
                        <span>{financialData.data[0].Commision_FK}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Комиссия платежки Paypalych %</td>
                    <td>
                        <span>{financialData.data[0].Commision_PP}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Комиссия платежки GameMoney %</td>
                    <td>
                        <span>{financialData.data[0].Commision_GM}</span>
                        <span>%</span>
                    </td>
                </tr>
                <tr>
                    <td>Курс продажи игр</td>
                    <td>{financialData.data[0].Currency_SellerGames}</td>
                </tr>
                <tr>
                    <td>Информация о последнем выводе средств</td>
                    <td>{financialData.data[0].Last_Payout_Info}</td>
                </tr>
                </tbody>
            </table>
        </div>
    )
}