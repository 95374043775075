
export const SteamPrice = ({oldPrice, region, oldPriceRub}) => {

    const currencySymbols = {
        'RU': '₽',
        'AZ': '$',
        'KZ': '₸',
        'UA': '₴',
        'default': '?'
    };
    const currencySymbol = currencySymbols[region] || currencySymbols['default'];

    return(
        <span>
            {oldPrice}{currencySymbol}
            {region === 'RU' ? '' : `/${oldPriceRub}₽`}
        </span>
    )
}