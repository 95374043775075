import React, { createContext, useContext, useEffect, useState } from "react";
import ServerLink from "./ServerLink";
import serverLink from "./ServerLink";

const AuthContext = createContext();

function AuthProvider({ children }) {
    const [isAuthenticated, setIsAuthenticated] = useState(() => {
        return JSON.parse(localStorage.getItem("isAuthenticated")) || false;
    });

    const [userName, setUserName] = useState(() => {
        return JSON.parse(localStorage.getItem("name")) || "";
    });

    const login = async (loginData) => {
        try {
            const response = await fetch(serverLink().authUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(loginData),
            });

            const data = await response.json();

            if (data.success) {
                localStorage.setItem("access_token", data.access_token);
                setIsAuthenticated(true);
                setUserName(data.name);
            } else {
                setIsAuthenticated(false);
                throw new Error(data.error);
            }
        } catch (error) {
            setIsAuthenticated(false);
            throw new Error("Ошибка авторизации");
        }
    };

    const logout = () => {
        // Реализуйте выход пользователя
        setIsAuthenticated(false);
        setUserName(null);
        localStorage.setItem("access_token", null);
    };

    // Используем useEffect для сохранения состояния авторизации в localStorage
    useEffect(() => {
        localStorage.setItem("isAuthenticated", JSON.stringify(isAuthenticated));
    }, [isAuthenticated]);

    useEffect(() => {
        localStorage.setItem("name", JSON.stringify(userName));
    }, [userName]);

    return (
        <AuthContext.Provider value={{ isAuthenticated, login, logout, userName }}>
            {children}
        </AuthContext.Provider>
    );
}

function useAuth() {
    return useContext(AuthContext);
}

export { AuthProvider, useAuth };
