function ErrorStatusCard({InfoData}) {
    return(
        <div className="status_succ_card">
            <div className="succ_image">
                <span className="errorIcon">
                    <i className='bx bx-x'></i>
                </span>
            </div>
            <div className="succ_title">
                {InfoData.status}
            </div>
            <div className="succ_description">
                Свяжитесь с нами в графе Oplata.info "Переписка с Продавцом" для получения товара!
            </div>
            <div className="succ_button">
                <a target="_blank" rel="noopener noreferrer" href="https://digiseller.market/info/">Связаться</a>
            </div>
        </div>
    )
}

export default ErrorStatusCard;