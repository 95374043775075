import React, { useState, useEffect, useRef } from 'react';
import {motion} from "framer-motion";

const Modal = (props) => {
    const [isDragging, setIsDragging] = useState(false);
    const [modalFocus, setModalFocus] = useState(false);
    const modalRef = useRef(null);

    const handleMouseDown = (e) => {
        setIsDragging(true);
        const offsetX = e.clientX - props.modalPosition.x;
        const offsetY = e.clientY - props.modalPosition.y;
        props.setDragOffset({ x: offsetX, y: offsetY });
        setModalFocus(true);
    };

    const handleMouseUp = () => {
        setIsDragging(false);
    };

    const handleClose = () => {
        const x = modalRef.current.offsetWidth;
        const y = modalRef.current.offsetHeight;

        props.setModalSize({ x, y });
        props.onModalClose();
    };

    useEffect(() => {
        const handleMouseMove = (e) => {
            if (isDragging) {
                const newX = e.clientX - props.dragOffset.x;
                const newY = e.clientY - props.dragOffset.y;
                props.setModalPosition({ x: newX, y: newY });
            }
        };

        if (isDragging) {
            window.addEventListener('mousemove', handleMouseMove);
            window.addEventListener('mouseup', handleMouseUp);
        } else {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        }
        return () => {
            window.removeEventListener('mousemove', handleMouseMove);
            window.removeEventListener('mouseup', handleMouseUp);
        };
    }, [isDragging, props.dragOffset]);

    useEffect(() => {
        const handleClickOutsideModal = (e) => {
            if (modalRef.current && !modalRef.current.contains(e.target)) {
                setModalFocus(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutsideModal);

        return () => {
            document.removeEventListener('mousedown', handleClickOutsideModal);
        };
    }, []);

    useEffect(() => {
        if (props.isOpened) {
            setModalFocus(true);
        }
    }, [props.isOpened]);


    return (
        <div className={`modal_background ${props.isOpened ? 'active' : ''}`}>
            <div
                className={`modal ${modalFocus ? 'view' : ''}`}
                ref={modalRef}
                style={{ transform: `translate(${props.modalPosition.x}px, ${props.modalPosition.y}px)`,
                    width: `${props.modalSize.x}px`,
                    height: `${props.modalSize.y}px`,
                    top: `calc(50% - ${props.modalSize.y / 2}px)`,
                    left: `calc(50% - ${props.modalSize.x / 2}px)`,
                }}
            >
                <div className="modal-header" onMouseDown={handleMouseDown}>
                    <span>{props.Title}</span>
                    <motion.span
                        whileHover={{scale: 1.1}}
                        whileTap={{scale: 0.8}}
                        className="close-btn bx bx-x"
                        onClick={handleClose}>

                    </motion.span>
                </div>
                <div className="modal-content">{props.Children}</div>
            </div>
        </div>
    );
};

export default Modal;
