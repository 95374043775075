import React, { useState } from 'react';
import {ServiceAdd} from "./serviceAdd";
import {Parameters} from "./parameters";
import {motion, Reorder, AnimatePresence} from "framer-motion";

export const ServiceContent = ({serviceData, setServiceData, data, setData, handleSendData, handleDelete}) => {
    const [showEmptyRow, setShowEmptyRow] = useState(false);

    // console.log(serviceData);
    const handleToggleEmptyRow = () => {
        setShowEmptyRow(!showEmptyRow);
    };


    return(
        <div className='table_content service'>
            <table className="tableFinancial">
                <thead>
                <tr>
                    <th>№</th>
                    <th>Digi Id</th>
                    <th>Сервис</th>
                    <th>Опции</th>
                    <th></th>
                </tr>
                </thead>

                <Reorder.Group as='tbody' axis='y' values={serviceData.data} onReorder={(newData) => setServiceData({ ...serviceData, data: newData })}>
                    {serviceData.data.map((service, index) => (
                        <Reorder.Item as='tr' key={service.service_id} value={service}
                                      transition={{ duration: 0 }}
                        >
                            <td>{index + 1}</td>
                            <td>
                                {service.digi_ids.split(',').map((id, idx) => (
                                    <span
                                        className="serviceSpan"
                                        key={idx}>
                                <span className="serviceSpanId">{id}</span>
                                <span className="serviceSpanTitle">{service.digi_titles.split(',')[idx]}</span>
                            </span>
                                ))}
                            </td>
                            <td>
                                <span
                                    className="serviceSpan"
                                >
                                    <span className="serviceSpanId api">{service.service_id}</span>
                                    <span className="serviceSpanTitle">{service.service_title}</span>
                                </span>
                            </td>
                            <td>
                                <Parameters service={service}/>
                            </td>
                            <td>
                                <motion.button
                                    whileHover={{scale: 1.2}}
                                    whileTap={{scale: 0.7}}
                                    onClick={() => handleDelete(service.service_id)}
                                    className="bx bxs-trash serviceNewButton delete"></motion.button>
                            </td>
                        </Reorder.Item>
                    ))}
                    <AnimatePresence mode={'wait'}>
                        {showEmptyRow ? (
                            <ServiceAdd
                                data={data}
                                setData={setData}
                                handleSendData={handleSendData}
                            />
                        ) : null}
                    </AnimatePresence>
                </Reorder.Group>

            </table>
            <div className="serviceNewRow">
                <div className="serviceInfo">
                    <span className='serviceDigi'></span> Данные с сервиса Digiseller
                    <span className='serviceApi'></span> Данные с API или нашего сервиса
                    <span className='border'></span>
                    <div className='servicePS'>Если товар с произвольной суммой, то установить 666!</div>
                </div>
                <motion.button
                    whileHover={{scale: 1.2}}
                    whileTap={{scale: 0.7}}
                    className={`serviceNewButton bx ${showEmptyRow ? 'bx-minus' : 'bx-plus'}`}
                    onClick={handleToggleEmptyRow}></motion.button>
            </div>
        </div>
    )
}