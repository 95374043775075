import {motion} from "framer-motion";
export const ServiceAdd = ({data, setData, handleSendData}) => {


    const handleAddFields = (fieldType) => {
        const updatedRow = { ...data };
        let newField = {};
        switch (fieldType) {
            case 'digiFields':
                newField = { digiId: '', digiName: '' };
                break;
            case 'serviceFields':
                newField = { serviceId: '', serviceName: '' };
                break;
            case 'optionFields':
                newField = { optionId: '', optionIdDigi: '', optionName: '' };
                break;
            case 'subOptionFields':
                newField = { subOptionId: '', subOptionIdDigi: '', subOptionName: '' };
                break;
            default:
                break;
        }
        updatedRow[fieldType].push(newField);
        setData(updatedRow);
    };

    const handleRemoveFields = (fieldType, fieldIndex) => {
        const updatedRow = { ...data };
        updatedRow[fieldType].splice(fieldIndex, 1);
        setData(updatedRow);
    };

    const handleChange = (fieldType, fieldIndex, fieldName, value) => {
        const updatedRow = { ...data };
        updatedRow[fieldType][fieldIndex][fieldName] = value;
        setData(updatedRow);
    };

    return (
        <motion.tr className="lineServiceAdd"
                   transition={{duration: 0.2}}
                   exit={{height: 0, opacity: 0}}
                   initial={{height: 0, opacity: 0}}
                   animate={{height: '100%', opacity: 1}}
                   style={{overflow: "hidden"}}
        >
            <td></td>
            <td>
                {data.digiFields.map((field, fieldIndex) => (
                    <span key={fieldIndex} className="tdInputSpanMinus">
                        <span className="tdInputSpan">
                            <input
                                required={true}
                                type="text"
                                value={field.digiId}
                                placeholder={`Id товара Digiseller ${fieldIndex + 1}`}
                                onChange={(e) => handleChange('digiFields', fieldIndex, 'digiId', e.target.value)}
                            />
                            <input
                                required={true}
                                type="text"
                                value={field.digiName}
                                placeholder="Подпись (GGsell)"
                                onChange={(e) => handleChange('digiFields', fieldIndex, 'digiName', e.target.value)}
                            />
                        </span>
                        <motion.button
                            whileHover={{scale: 1.2}}
                            whileTap={{scale: 0.7}}
                            className="bx bx-minus serviceRemove"
                                onClick={() => handleRemoveFields('digiFields', fieldIndex)}></motion.button>
                    </span>
                ))}
                <motion.button
                    whileHover={{scale: 1.2}}
                    whileTap={{scale: 0.7}}
                    className="bx bx-plus serviceNewButton options" onClick={() => handleAddFields('digiFields')}></motion.button>
            </td>
            <td>
                {data.serviceFields.map((field, fieldIndex) => (
                    <span key={fieldIndex} className="tdInputSpanMinus">
                        <span className="tdInputSpan">
                        <input
                            required={true}
                            type="text"
                            value={field.serviceId}
                            placeholder={`Id сервиса API ${fieldIndex + 1}`}
                            onChange={(e) => handleChange('serviceFields', fieldIndex, 'serviceId', e.target.value)}
                        />
                        <input
                            required={true}
                            type="text"
                            value={field.serviceName}
                            placeholder={`Подпись (Brawl Stars)`}
                            onChange={(e) => handleChange('serviceFields', fieldIndex, 'serviceName', e.target.value)}
                        />
                        </span>
                        {/*<button className="bx bx-minus"*/}
                        {/*        onClick={() => handleRemoveFields('serviceFields', fieldIndex)}></button>*/}
                    </span>
                ))}
                <button
                    className="bx bx-plus serviceNewButton options hidden"
                    // onClick={() => handleAddFields('serviceFields')}
                ></button>
            </td>
            <td>
                {data.optionFields.map((field, fieldIndex) => (
                    <span key={fieldIndex} className="tdInputSpanMinus">
                        <span className="tdInputSpan">
                            <input
                                required={true}
                                type="text"
                                value={field.optionId}
                                placeholder={`Id опции API ${fieldIndex + 1}`}
                                onChange={(e) => handleChange('optionFields', fieldIndex, 'optionId', e.target.value)}
                            />
                            <input
                                required={true}
                                type="text"
                                value={field.optionIdDigi}
                                placeholder={`Id опции Digi ${fieldIndex + 1}`}
                                onChange={(e) => handleChange('optionFields', fieldIndex, 'optionIdDigi', e.target.value)}
                            />
                            <input
                                required={true}
                                type="text"
                                value={field.optionName}
                                placeholder={`Подпись (170 + 17 Gems)`}
                                onChange={(e) => handleChange('optionFields', fieldIndex, 'optionName', e.target.value)}
                            />
                        </span>
                        <motion.button
                            whileHover={{scale: 1.2}}
                            whileTap={{scale: 0.7}}
                            className="bx bx-minus serviceRemove"
                                onClick={() => handleRemoveFields('optionFields', fieldIndex)}></motion.button>
                    </span>
                ))}
                <motion.button
                    whileHover={{scale: 1.2}}
                    whileTap={{scale: 0.7}}
                    className="bx bx-plus serviceNewButton options" onClick={() => handleAddFields('optionFields')}></motion.button>
            </td>
            {/*<td>*/}
            {/*    {data.subOptionFields.map((field, fieldIndex) => (*/}
            {/*        <div key={fieldIndex}>*/}
            {/*            <input*/}
            {/*                type="text"*/}
            {/*                value={field.subOptionId}*/}
            {/*                placeholder={`Id подопции API ${fieldIndex + 1}`}*/}
            {/*                onChange={(e) => handleChange('subOptionFields', fieldIndex, 'subOptionId', e.target.value)}*/}
            {/*            />*/}
            {/*            <input*/}
            {/*                type="text"*/}
            {/*                value={field.subOptionIdDigi}*/}
            {/*                placeholder={`Id подопции Digi ${fieldIndex + 1}`}*/}
            {/*                onChange={(e) => handleChange('subOptionFields', fieldIndex, 'subOptionIdDigi', e.target.value)}*/}
            {/*            />*/}
            {/*            <input*/}
            {/*                type="text"*/}
            {/*                value={field.subOptionName}*/}
            {/*                placeholder={`Подпись (Suboption)`}*/}
            {/*                onChange={(e) => handleChange('subOptionFields', fieldIndex, 'subOptionName', e.target.value)}*/}
            {/*            />*/}
            {/*            <button className="bx bx-minus"*/}
            {/*                    onClick={() => handleRemoveFields('subOptionFields', fieldIndex)}></button>*/}
            {/*        </div>*/}
            {/*    ))}*/}
            {/*    <button className="bx bx-plus" onClick={() => handleAddFields('subOptionFields')}></button>*/}
            {/*</td>*/}
            <td>
                <motion.button
                    whileHover={{scale: 1.2}}
                    whileTap={{scale: 0.7}}
                    onClick={handleSendData} className="bx bxs-send serviceRemove"></motion.button>
            </td>
        </motion.tr>
    );
}