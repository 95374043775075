import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";
import SuccStatusCard from "./SuccStatusCard";
import ProgressStatusCard from "./ProgressStatusCard";
import ErrorStatusCard from "./ErrorStatusCard";
import Loader from "./Loader";

function StatusCard() {
    const [InfoData, setInfoData] = useState({
        name: '',
        status: '/',
        statusInfo: '/'
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Get the uniquecode from the address bar
        const searchParams = new URLSearchParams(window.location.search);
        const uniquecode = searchParams.get('uniquecode');

        if (uniquecode) {
            // Fetch the initial status
            sendUniqueCodeToServer(uniquecode);

            // Start fetching the status every 2 seconds
            const intervalId = setInterval(() => {
                // Check if the current status is "Игра отправлена на Ваш аккаунт!"
                if (InfoData.status === "Игра отправлена на Ваш аккаунт!") {
                    clearInterval(intervalId); // Остановить интервал, если получен желаемый статус
                    return;
                }
                sendUniqueCodeToServer(uniquecode);
            }, 2000);

            // Clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        } else {
            // If no uniquecode, set the gameData to "No transaction"
            setInfoData({
                name: '',
                status: 'Ваша покупка не найдена',
                statusInfo: 'Выдача невозможна'
            });
            setIsLoading(false);
        }
    }, [InfoData.status]);

    // Function to send the uniquecode to the server
    const sendUniqueCodeToServer = (uniquecode) => {
        const apiUrl = ServerLink().SteamUrl;

        // Data to send to the server (in this case, we send it as JSON)
        const data = {
            uniquecode: uniquecode,
            object: 'status'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the server response and update the gameData
                setInfoData({
                    name: data.name,
                    status: data.status,
                    statusInfo: data.statusInfo
                });
                setIsLoading(false); // Data is loaded, set isLoading to false
            })
            .catch((error) => {
                // console.error('Error sending uniquecode to the server:', error);
                // Set the gameData to "Ошибка юникода" or "Ошибка заявки" in case of an error
                setInfoData({
                    name: '/',
                    status: 'Сервер недоступен',
                    statusInfo: '/'
                });
                setIsLoading(false); // Data is loaded, set isLoading to false
            });
    };

    // Словарь ошибок
    const errorMessages = {
        'Сервер недоступен': 'Сервер недоступен',
        'Бот не смог соединиться со Steam серверами. Ожидайте заявку от нового Бота': 'Бот не смог соединиться со Steam серверами. Ожидайте заявку от нового Бота',
        'Бот потерял связь, повторная выдача запущена, ожидайте': 'Бот потерял связь, повторная выдача запущена, ожидайте',
        'Вы неверно указали url': 'Вы неверно указали url',
        'Ошибка на этапе получения данных от сайта': 'Ошибка на этапе получения данных от сайта',
        'Бот не смог добавить Вас в друзья': 'Бот не смог добавить Вас в друзья',
        'Бот не смог добавить Вас в друзья, повторная выдача с другого Бота, примите его в Друзья': 'Бот не смог добавить Вас в друзья, повторная выдача с другого Бота, примите его в Друзья',
        'Вы не успели принять заявку от Бота за отведенное время': 'Вы не успели принять заявку от Бота за отведенное время',
        'Ошибка получения subID': 'Ошибка получения subID',
        'Ошибка добавления товара в корзину': 'Ошибка добавления товара в корзину',
        'Бота остановила система VAC': 'Бота остановила система VAC',
        'Бота остановила система VAC, повторная выдача с другого Бота, примите его в Друзья': 'Бота остановила система VAC, повторная выдача с другого Бота, примите его в Друзья',
        'Ошибка инициализации транзакции': 'Ошибка инициализации транзакции',
        'Регион Вашего аккаунта не позволяет передать игру': 'Регион Вашего аккаунта не позволяет передать игру',
        'Бот оказался перегружен': 'Бот оказался перегружен',
        'Бот оказался перегружен, повторная выдача с другого Бота, примите его в Друзья': 'Бот оказался перегружен, повторная выдача с другого Бота, примите его в Друзья',
        'Ошибка инициализации цены товара': 'Ошибка инициализации цены товара',
        'Ошибка транзакции': 'Ошибка транзакции',
        'Незарегистрированная ошибка Steam': 'Незарегистрированная ошибка Steam',
        'Ошибка транзакции, повторная выдача с другого Бота, примите его в Друзья': 'Ошибка транзакции, повторная выдача с другого Бота, примите его в Друзья'
    };

    return (
        <div className="card status_card">
            {isLoading ? (
                <Loader /> // Show loader while data is being fetched
            ) : InfoData.status === "Игра отправлена на Ваш аккаунт!" ? (
                <SuccStatusCard InfoData={InfoData} />
            ) : InfoData.status in errorMessages ? (
                <ErrorStatusCard InfoData={InfoData} message={errorMessages[InfoData.status]} />
            ) : (
                <ProgressStatusCard InfoData={InfoData} />
            )}
        </div>
    );
}

export default StatusCard;
