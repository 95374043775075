import React, { useState } from 'react';
import TransactionsHeader from '../Transactions/TransactionsHeader';
import TransactionsTabs from '../Transactions/TransactionsTabs';
import GamesHeader from '../Games/GamesHeader';
import Games from '../Games/Games';
import AccountsHeader from '../Accounts/AccountsHeader';
import {Accounts} from "../Accounts/Accounts";
import ProxyHeader from '../Proxy/ProxyHeader';
import Proxy from '../Proxy/Proxy';
import Financial from "../Financial/financial";
import Exchange from "../Exchange/exchange";
import Service from "../Services/service";
import UserLogs from "../Logs/userLogs";

export const useModalConfig = () => {
    const [modal, setModal] = useState({
        transactions: false,
        games: false,
        accounts: false,
        proxy: false,
        giftLogs: false,
        parceLogs: false,
        adminLogs: false,
        settings: false,
        financial: false,
        exchange: false,
        services: false
    });

    const [dragOffset, setDragOffset] = useState({
        transactions: { x: 0, y: 0 },
        games: { x: 0, y: 0 },
        accounts: { x: 0, y: 0 },
        proxy: { x: 0, y: 0 },
        giftLogs: { x: 0, y: 0 },
        parceLogs: { x: 0, y: 0 },
        adminLogs: { x: 0, y: 0 },
        settings: { x: 0, y: 0 },
        financial: {x: 0, y: 0},
        exchange: {x: 0, y: 0},
        services: {x: 0, y: 0}
    });

    const [modalPositions, setModalPositions] = useState({
        transactions: { x: 0, y: 0 },
        games: { x: 0, y: 0 },
        accounts: { x: 0, y: 0 },
        proxy: { x: 0, y: 0 },
        giftLogs: { x: 0, y: 0 },
        parceLogs: { x: 0, y: 0 },
        adminLogs: { x: 0, y: 0 },
        settings: { x: 0, y: 0 },
        financial: {x: 0, y: 0},
        exchange: {x: 0, y: 0},
        services: {x: 0, y: 0}
    });

    const [modalSize, setModalSize] = useState({
        transactions: { x: 1280, y: 720 },
        games: { x: 1280, y: 720 },
        accounts: { x: 1280, y: 720 },
        proxy: { x: 850, y: 720 },
        giftLogs: { x: 1280, y: 720 },
        parceLogs: { x: 1280, y: 720 },
        adminLogs: { x: 1280, y: 720 },
        settings: { x: 320, y: 600 },
        financial: {x: 430, y: 600},
        exchange: {x: 422, y: 222},
        services: {x: 1280, y: 720}
    });

    const modalConfigs = [
        {
            key: 'transactions',
            title: <TransactionsHeader />,
            ContentComponent: TransactionsTabs,
        },
        {
            key: 'games',
            title: <GamesHeader />,
            ContentComponent: Games,
        },
        {
            key: 'accounts',
            title: <AccountsHeader />,
            ContentComponent: Accounts,
        },
        {
            key: 'proxy',
            title: <ProxyHeader />,
            ContentComponent: Proxy,
        },
        {
            key: 'giftLogs',
            title: 'Логи выдачи',
            ContentComponent: UserLogs,
        },
        {
            key: 'parceLogs',
            title: 'Логи парса цен',
            ContentComponent: UserLogs,
        },
        {
            key: 'adminLogs',
            title: 'Логи действий',
            ContentComponent: UserLogs,
        },
        {
            key: 'settings',
            title: 'Настройки',
            ContentComponent: <></>,
        },
        {
            key: 'financial',
            title: 'Настройки отчета',
            ContentComponent: Financial,
        },
        {
            key: 'exchange',
            title: 'Курс Steam',
            ContentComponent: Exchange,
        },
        {
            key: 'services',
            title: 'Сервисы',
            ContentComponent: Service,
        },
    ];
    return { modal, setModal, dragOffset, setDragOffset, modalPositions, setModalPositions, modalSize, setModalSize, modalConfigs };
};