import React, {createContext, useContext, useEffect, useState} from 'react';
const ErrorContext = createContext();
export const useError = () => useContext(ErrorContext);

export const ErrorProvider = ({ children }) => {
    const [error, setError] = useState(null);

    useEffect(() => {
        let timer;
        if (error) {
            timer = setTimeout(() => {
                setError(null);
            }, 5000);
        }

        return () => {
            clearTimeout(timer);
        };
    }, [error]);

    const handleErrorVision = () => {
        setError(null);
    };

    return (
        <ErrorContext.Provider value={{ error, setError, handleErrorVision }}>
            {children}
        </ErrorContext.Provider>
    );
};
