import GameCard from "../Components/GameCard";
import ProfileCard from "../Components/ProfileCard";
import StatusCard from "../Components/StatusCard";
import SteamStatusCard from "../Components/SteamStatusCard";
import Recommendations from "../Components/Recommendations";
import AnimatedPage from "./animatedPage";
function Gift(){


    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth', // Добавляем плавную прокрутку
        });
    };

    return(
        <AnimatedPage>
            <main>
                <div className="main_container">
                    <div className="main_gift">
                        <div className="main_row">
                            <SteamStatusCard
                                message="Мы перенесли bikskey на новую систему для лучшей эффективности. Если вы обнаружите какие-либо проблемы или ошибки, пожалуйста, дайте нам знать. Ваше участие ценно!"/>
                        </div>
                        <div className="main_row">
                            <GameCard/>
                        </div>
                        <div className="main_row">
                            <ProfileCard/>
                            <StatusCard/>
                        </div>
                        <div className="down">
                            <div className="down_left" onClick={scrollToBottom}>
                            <span className="down_arrow">
                                <i className='bx bxs-chevrons-down'></i>
                            </span>
                            </div>
                            <div className="down_right" onClick={scrollToBottom}>
                            <span className="down_arrow">
                                <i className='bx bxs-chevrons-down'></i>
                            </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Recommendations/>
            </main>
        </AnimatedPage>
    );
}

export default Gift;