import {Link} from 'react-router-dom';
import AnimatedPage from "./animatedPage";

function Other(){

    return(
        <AnimatedPage>
            <main>
                <div className="main_wrapper">
                    <div className="card">
                        <div className="main_card">
                            <div className="card_row">
                                <div className="main_img">
                                    <i>404</i>
                                </div>
                            </div>
                            <div className="card_row">
                        <span className="main_text">
                            <span><Link to="/">bikskey</Link></span>
                            <span>Страница не найдена</span>
                        </span>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AnimatedPage>
    );
}

export default Other;