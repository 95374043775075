import Profile from "../img/avatar.jpg";
import React, { useState, useEffect } from 'react';
import ServerLink from "./ServerLink";
import Loader from "./Loader";

function ProfileCard() {
    const [InfoData, setInfoData] = useState({
        name: '/',
        link: '/',
        image: '/'
    });

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Get the uniquecode from the address bar
        const searchParams = new URLSearchParams(window.location.search);
        const uniquecode = searchParams.get('uniquecode');

        if (uniquecode) {
            // Send the uniquecode to the server
            setTimeout(() => {
                sendUniqueCodeToServer(uniquecode);
            }, 2000);
        } else {
            // If no uniquecode, set the gameData to "No transaction"
            setInfoData({
                name: 'Профиль не найден',
                link: 'https://steamcommunity.com/',
                image: Profile
            });
            setIsLoading(false);
        }
    }, []);

    // Function to send the uniquecode to the server
    const sendUniqueCodeToServer = (uniquecode) => {
        const apiUrl = ServerLink().SteamUrl;

        // Data to send to the server (in this case, we send it as JSON)
        const data = {
            uniquecode: uniquecode,
            object: 'profile'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the server response and update the gameData
                setInfoData({
                    name: data.name,
                    link: data.link,
                    image: data.image
                });
                setIsLoading(false);
            })
            .catch((error) => {
                console.error('Error sending uniquecode to the server:', error);
                setIsLoading(false);
            });
    };
    return(
        <div className="card profile_card">
            {isLoading ? (
                <Loader/>
            ):
                <>
                    <div className="profile_image">
                        <img src={InfoData.image} alt=""/>
                    </div>
                    <div className="profile_title">
                        {InfoData.name}
                    </div>
                    <div className="profile_link">
                        {InfoData.link}
                    </div>
                    <div className="profile_button">
                        <a target="_blank" rel="noopener noreferrer" href={InfoData.link}>Перейти в профиль</a>
                    </div>
                </>
            }
        </div>
    )
}

export default ProfileCard;