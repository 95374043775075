function ServerLink() {
    // const ServerLinks = {
    //     searchUrl: 'https://bikskey.ru/enginePHP/server.php?file=Search.php',
    //     SteamUrl: 'https://bikskey.ru/enginePHP/server.php?file=Steam.php',
    //     AuthUrl: 'https://bikskey.ru/enginePHP/server.php?file=Auth.php',
    //     LinesUrl: 'https://bikskey.ru/enginePHP/server.php?file=Lines.php',
    //     TableUrl: 'https://bikskey.ru/enginePHP/server.php?file=Table.php',
    //     EditGameUrl: 'https://bikskey.ru/enginePHP/server.php?file=Edit.php',
    //     RetryTransaction: 'https://bikskey.ru/enginePHP/server.php?file=RetryTransaction.php',
    //     BufferUrl: 'https://bikskey.ru/enginePHP/server.php?file=buffer.php',
    //     QiwiUrl: 'https://bikskey.ru/enginePHP/server.php?file=Qiwi.php',
    //     RetryQiwiUrl: 'https://bikskey.ru/enginePHP/server.php?file=RetryQiwi.php',
    // };
    const ServerLinks = {
        searchUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Search.php',
        SteamUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Steam.php',
        AuthUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Auth.php',
        LinesUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Lines.php',
        TableUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Table.php',
        EditGameUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Edit.php',
        RetryTransaction: 'https://topup.bikskey.ru/enginePHP/server.php?file=RetryTransaction.php',
        BufferUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=buffer.php',
        QiwiUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=Qiwi.php',
        RetryQiwiUrl: 'https://topup.bikskey.ru/enginePHP/server.php?file=RetryQiwi.php',

        authUrl: 'https://api.bikskey.ru/topup/api/auth',
        serviceListUrl: 'https://api.bikskey.ru/topup/api/list/service',
        updateListUrl: 'https://api.bikskey.ru/topup/api/list/update',
        topUpBalanceUrl: 'https://api.bikskey.ru/topup/api/service/top_up_balance',
        addToListUrl: 'https://api.bikskey.ru/topup/api/list/add_to',
        deleteListUrl: 'https://api.bikskey.ru/topup/api/list/delete',
        paymentUrl: 'https://api.bikskey.ru/topup/api/service/payment',
        paymentStatusUrl: 'https://api.bikskey.ru/topup/api/service/payment/status',
        logListUrl: 'https://api.bikskey.ru/topup/api/list/logs'
    };
    // const ServerLinks = {
    //     searchUrl: 'http://localhost:8080/server.php?file=Search.php',
    //     SteamUrl: 'http://localhost:8080/server.php?file=Steam.php',
    //     AuthUrl: 'http://localhost:8080/server.php?file=Auth.php',
    //     LinesUrl: 'http://localhost:8080/server.php?file=Lines.php',
    //     TableUrl: 'http://localhost:8080/server.php?file=Table.php',
    //     EditGameUrl: 'http://localhost:8080/server.php?file=Edit.php',
    //     RetryTransaction: 'http://localhost:8080/server.php?file=RetryTransaction.php',
    //     BufferUrl: 'http://localhost:8080/server.php?file=buffer.php',
    //     QiwiUrl: 'http://localhost:8080/server.php?file=Qiwi.php',
    //     RetryQiwiUrl: 'http://localhost:8080/server.php?file=RetryQiwi.php',
    //
    //     authUrl: 'http://127.0.0.1:5002/api/auth',
    //     serviceListUrl: 'http://127.0.0.1:5002/api/list/service',
    //     updateListUrl: 'http://127.0.0.1:5002/api/list/update',
    //     topUpBalanceUrl: 'http://127.0.0.1:5002/api/service/top_up_balance',
    //     addToListUrl: 'http://127.0.0.1:5002/api/list/add_to',
    //     deleteListUrl: 'http://127.0.0.1:5002/api/list/delete',
    //     paymentUrl: 'http://127.0.0.1:5002/api/service/payment',
    //     paymentStatusUrl: 'http://127.0.0.1:5002/api/service/payment/status',
    //     logListUrl: 'http://127.0.0.1:5002/api/list/logs'
    // };
    return ServerLinks;
}

export default ServerLink;