import React, { useState, useEffect, useCallback } from 'react';
import ServerLink from "../ServerLink";
import Loader from "../Loader";
import { getToServer } from "../ToServer/getToServer";
import { Table } from "./table";

const Games = () => {
    const [tableData, setTableData] = useState({ data: [], isLoading: false, error: null });

    useEffect(() => {
        getToServer('games', ServerLink().serviceListUrl, 'None', setTableData, () => ({}));
    }, []);

    const handleRefresh = useCallback(() => {
        getToServer('games', ServerLink().serviceListUrl, 'None', setTableData, () => ({}), 'silent');
    }, []);

    // console.log(tableData.data);

    return (
        <>
            {tableData.isLoading ? (
                <Loader />
            ) : (
                <Table
                    tableData={tableData.data}
                    isLoading={tableData.isLoading}
                    setTableData={setTableData}
                    handleRefresh={handleRefresh}
                />
            )}
        </>
    );
};

export default React.memo(Games);
