import {motion} from "framer-motion";
const MoreButton = ({ modal, setModal }) => {

    return (
        <>
            <div className='card moreCard'>
                <div className="card_row moreCardRow">
                    <motion.button
                        // whileHover={{scale: 0.95, 'background-color': '#cccccc', color: '#171717'}}
                        // whileTap={{scale: 0.9, 'background-color': '#cccccc', color: '#171717'}}
                        // onClick={() => setModal({...modal, exchange: true})}
                        className='btnMoreSettings btn_close'>
                        <span>
                            <i className='bx bxs-dollar-circle'></i>
                        </span>
                        <span>Курс Steam</span>
                    </motion.button>
                    <motion.button
                        // whileHover={{scale: 0.95, 'background-color': '#cccccc', color: '#171717'}}
                        // whileTap={{scale: 0.9, 'background-color': '#cccccc', color: '#171717'}}
                        // onClick={() => setModal({...modal, proxy: true})}
                        className='btnMoreSettings btn_close'>
                        <span>
                            <i className='bx bxs-server'></i>
                        </span>
                        <span>Прокси</span>
                    </motion.button>
                </div>
                <div className="card_row moreCardRow">
                    <motion.button
                        whileHover={{scale: 0.95, 'background-color': '#cccccc', color: '#171717'}}
                        whileTap={{scale: 0.9, 'background-color': '#cccccc', color: '#171717'}}
                        onClick={() => setModal({...modal, services: true})} className='btnMoreSettings'>
                        <span>
                            <i className='bx bxs-gift'></i>
                        </span>
                        <span>Сервисы</span>
                    </motion.button>
                </div>
            </div>

        </>
    );
};

export default MoreButton;
