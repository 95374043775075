import React, { useState, useEffect } from "react";
import Search from "./Search";
import { Link } from 'react-router-dom';
import { useAuth } from "./AuthProvider"; // Импортируем useAuth
import {motion, AnimatePresence, spring} from "framer-motion";

function Header({modal, setModal}) {
    const { isAuthenticated, logout, userName } = useAuth(); // Получаем isAuthenticated и logout из провайдера
    const [showDropdown, setShowDropdown] = useState(false);
    const toggleDropdown = () => {
        setShowDropdown((prevState) => !prevState);
    };

    const handleLogout = () => {
        logout();
    };

    const dropdownVariants = {
        hidden: {
            opacity: 0,
            y: '-20px',
            scale: 1,
            transition: {
                duration: 0.3,
                type: 'spring',
                stiffness: 80
            }
        },
        visible: {
            opacity: 1,
            y: 0,
            scale: 1,
            transition: {
                duration: 0.3,
                type: 'spring',
                stiffness: 80
            }
        },
    };

    return (
        <header className=''>
            <motion.div className="header"
                initial={{y: '-100px'}}
                animate={{y: '0px'}}
                transition={{ y: { duration: 0.5 } }}
            >
                <div className="header_left">
                    <Link to="/">
                        <motion.div
                            whileHover={{ scale: 0.98 }}
                            whileTap={{ scale: 0.97 }}
                        >
                            OSON
                            {/*BIKS*/}
                            {/*BattleKey*/}
                        </motion.div>
                    </Link>
                </div>
                <Search/>
                <div className="header_right">
                    {isAuthenticated ? (
                        // Отображаем кнопку выхода, если пользователь авторизован
                        <>
                            <div
                                onClick={toggleDropdown}
                                className="drop_down"
                            >
                                <motion.div className="drop_down_title"
                                      whileHover={{ backgroundColor: 'var(--border-color)' }}
                                      whileTap={{ scale: 0.9, backgroundColor: 'var(--border-color)' }}
                                ><i className='bx bx-dots-vertical-rounded'></i></motion.div>
                                <AnimatePresence>
                                    {showDropdown && isAuthenticated && (

                                            <motion.div
                                                className="drop_down_content"
                                                initial="hidden"
                                                animate="visible"
                                                exit="hidden"
                                                variants={dropdownVariants}
                                            >
                                                <div className="drop_down_content_row row_title">
                                                    <i className='bx bx-user'></i> {userName}
                                                </div>
                                                <div className="drop_down_content_row">
                                                    <Link to="/admin" className=""><i className='bx bx-book-content'></i>Админ
                                                        панель</Link>
                                                </div>
                                                <div className="drop_down_content_row row_title settings disabled"
                                                     // onClick={() => setModal({ ...modal, financial: true })}
                                                >
                                                    <i className='bx bxs-cog'></i>Настройки
                                                </div>
                                                <div className="drop_down_content_row">
                                                    <Link to="#" onClick={handleLogout} className="logout_button"><i
                                                        className='bx bx-log-out'></i>Выйти</Link>
                                                </div>
                                            </motion.div>

                                    )}
                                </AnimatePresence>
                            </div>
                        </>
                    ) : (
                        <motion.div
                            whileHover={{backgroundColor: 'var(--border-color)'}}
                            whileTap={{scale: 0.9, backgroundColor: 'var(--border-color)'}}
                            className='info_box'
                        >
                            <Link to="/info" className="info_button bx bx-message-square-detail"></Link>
                        </motion.div>
                    )}
                </div>
            </motion.div>
        </header>
    );
}

export default Header;
