import Loader from "./Loader";
import SuccStatusCard from "./SuccStatusCard";
import ErrorStatusCard from "./ErrorStatusCard";
import React, {useEffect, useState} from "react";
import QiwiProgressCard from "./QiwiProgressCard";
import ServerLink from "./ServerLink";


function QiwiStatusCard () {
    const [isLoading, setIsLoading] = useState(true);
    const [InfoData, setInfoData] = useState({
        success: '',
        status: '/',
        message: '/'
    });
    const errorMessages = {
        'Сервер недоступен': 'Сервер недоступен',
        'Ошибка': 'Ошибка',
        'Ваша покупка не найдена': 'Ваша покупка не найдена',
        'Ошибка верификации': 'Ошибка верификации',
        'Ошибка пополнения': 'Ошибка пополнения',
        'User not found': 'Пользователь не найден',
        'technical problem': 'Технические проблемы',
        'Account not found': 'Технические проблемы',
        'Technical problems on the part of the supplier': 'Технические проблемы',
        'Deposit is not enough': 'Технические проблемы'
    };
    useEffect(() => {
        // Get the uniquecode from the address bar
        const searchParams = new URLSearchParams(window.location.search);
        const uniquecode = searchParams.get('uniquecode');

        if (uniquecode) {
            // Fetch the initial status
            sendUniqueCodeToServer(uniquecode);

            // Start fetching the status every 2 seconds
            const intervalId = setInterval(() => {
                // Check if the current status is "Игра отправлена на Ваш аккаунт!"
                if (InfoData.status === "Баланс успешно пополнен") {
                    clearInterval(intervalId); // Остановить интервал, если получен желаемый статус
                    return;
                }
                sendUniqueCodeToServer(uniquecode);
            }, 2000);

            // Clear the interval when the component unmounts
            return () => clearInterval(intervalId);
        } else {
            // If no uniquecode, set the gameData to "No transaction"
            setInfoData({
                success: '',
                status: 'Ваша покупка не найдена',
                message: 'Выдача невозможна'
            });
            setIsLoading(false);
        }
    }, [InfoData.status]);

    // Function to send the uniquecode to the server
    const sendUniqueCodeToServer = (uniquecode) => {
        const apiUrl = ServerLink().paymentStatusUrl;

        // Data to send to the server (in this case, we send it as JSON)
        const data = {
            code: uniquecode
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // "Authorization": "Bearer " + localStorage.getItem("access_token")
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                // Handle the server response and update the gameData
                setInfoData({
                    success: data.success,
                    status: data.status,
                    message: data.message
                });
                // console.log(InfoData);
                setIsLoading(false); // Data is loaded, set isLoading to false
            })
            .catch((error) => {
                // console.log(InfoData);
                // console.error('Error sending uniquecode to the server:', error);
                // Set the gameData to "Ошибка юникода" or "Ошибка заявки" in case of an error
                setInfoData({
                    success: '/',
                    status: 'Сервер недоступен',
                    message: '/'
                });
                setIsLoading(false); // Data is loaded, set isLoading to false
            });
    };

    return(
        <>
            <div className="card status_card">
                {isLoading ? (
                    <Loader /> // Show loader while data is being fetched
                ) : InfoData.status === "Баланс успешно пополнен" ? (
                    <SuccStatusCard InfoData={InfoData} />
                ) : InfoData.status in errorMessages ? (
                    <ErrorStatusCard InfoData={InfoData} message={errorMessages[InfoData.status]} />
                ) : (
                    <QiwiProgressCard InfoData={InfoData} />
                )}
            </div>
        </>
    )
}

export default QiwiStatusCard;