import React, { useState, useEffect, useRef } from "react";
import ServerLink from "./ServerLink";
import SearchResult from "./SearchResult";
import {motion} from "framer-motion";

function Search() {
    const [uniquecode, setUniquecode] = useState("");
    const [infoData, setInfoData] = useState({
        status: 'None',
        link: ''
    });

    const searchRef = useRef(null);

    const search = (e) => {
        e.preventDefault();

        if (uniquecode.trim() === "" || uniquecode.length > 25) {
            console.log("Invalid unique code");
            return; // Если поле пустое или превышает максимальную длину, не выполняем поиск
        }

        const apiUrl = ServerLink().searchUrl;

        const data = {
            uniquecode: uniquecode,
            object: 'search'
        };

        fetch(apiUrl, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            body: JSON.stringify(data)
        })
            .then((response) => response.json())
            .then((data) => {
                setInfoData({
                    status: data.status,
                    link: data.link
                });
            })
            .catch((error) => {
                console.error('Error sending uniquecode to the server:', error);
            });
    }

    useEffect(() => {
        const handleOutsideClick = (e) => {
            if (searchRef.current && !searchRef.current.contains(e.target)) {
                setInfoData({
                    status: 'None',
                    link: ''
                });
            }
        };

        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        };
    }, []);

    return (
        <motion.div
            whileHover={{scale: 0.98}}
            whileTap={{scale: 0.96}}
            className="header_middle">
            <motion.input
                whileFocus={{borderColor: 'var(--purple-color)'}}
                placeholder="Введите код вашего заказа"
                className="header_search"
                type="text"
                value={uniquecode}
                onChange={(e) => setUniquecode(e.target.value)}
                maxLength={uniquecode}
            />
            <motion.button
                whileHover={{color: 'var(--purple-color)'}}
                onClick={search} className="header_search_button bx bx-search"></motion.button>
            {infoData.status !== "None" &&
                <SearchResult searchRef={searchRef} infoData={infoData} />
            }
        </motion.div>
    );
}

export default Search;
