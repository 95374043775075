import React, { useState, useCallback, useMemo } from 'react';
import ServerLink from "../ServerLink";
import { sendToServer } from "../ToServer/sendToServer";
import { Markup } from "./components/markup";
import { DigisellerAdd } from "./components/digisellerAdd";
import { AppUrl } from "./components/appUrl";
import { SteamPrice } from "./components/steamPrice";
import { SteamNewPrice } from "./components/steamNewPrice";
import { SellingPrice } from "./components/sellingPrice";
import style from "./games.module.css";
import { motion } from "framer-motion";

export const TableRow = React.memo(({ item, setTableData, handleRefresh, isUrlVisible, isTitleVisible }) => {
    const [isVisible, setVisible] = useState(false);

    const handleVisible = useCallback(() => {
        setVisible(prevVisible => !prevVisible);
    }, []);

    const markupComponents = useMemo(() =>
        Object.keys(item.markups).map(digiId => (
            <Markup key={digiId} item={item} digiId={digiId} isVisible={isVisible} />
        )), [item, isVisible]);

    const sellingPriceComponents = useMemo(() =>
        Object.keys(item.sellPrice).map(digiId => (
            <SellingPrice key={digiId} item={item.sellPrice[digiId]} />
        )), [item.sellPrice]);

    return (
        <tr key={item.id}>
            <td>{item.id}</td>
            <td className={style.markups}>
                {markupComponents}
                <DigisellerAdd isVisible={isVisible} game_id={item.id} />
                <motion.span
                    whileHover={{ opacity: 0.8 }}
                    onClick={handleVisible}
                    className={style.box_down}>
                    <motion.span
                        animate={{ rotate: isVisible ? 135 : -45 }}
                        className={`bx bxs-chevron-down ${style.down}`}></motion.span>
                </motion.span>
            </td>
            <td>
                <AppUrl url={item.app_url} isUrlVisible={isUrlVisible} />
            </td>
            <td>
                <AppUrl url={item.name} isUrlVisible={isTitleVisible} />
            </td>
            <td>{item.account_id}</td>
            <td>{item.region}</td>
            <td>
                <SteamPrice oldPrice={item.old_price} region={item.region} oldPriceRub={item.oldPriceRub} />
            </td>
            <td>{item.discount}%</td>
            <td>
                <SteamNewPrice newPrice={item.new_price} region={item.region} newPriceRub={item.newPriceRub} />
            </td>
            <td>
                <span className={style.selling_price_box}>
                    {sellingPriceComponents}
                </span>
            </td>
        </tr>
    );
});