import {useState} from "react";
import {motion} from "framer-motion";

function SuccStatusCard({InfoData}) {
    const [copied, setCopied] = useState(false);
    const handleCopy = () => {
        navigator.clipboard.writeText(InfoData.message)
            .then(() => {
                setCopied(true);
                setTimeout(() => setCopied(false), 2000);
            })
            .catch(err => console.error('Ошибка копирования текста:', err));
    };

    return(
        <div className="status_succ_card">
            <div className="succ_image">
                <span className="checkIcon">
                    <i className='bx bx-check-double'></i>
                </span>
            </div>
            <div className="succ_title">
                {InfoData.status}
            </div>

            {InfoData.message === 'None' ?
                <div className="succ_description">
                    Оставьте, пожалуйста, отзыв во вкладке “Отзыв о Товаре”
                </div>
                :
                <div className="succ_description payment">
                    <span>
                        Ваш гифт код:
                    </span>
                    <motion.button
                        whileHover={{scale: 1, opacity: 0.8, transition:{duration: 0.1}}}
                        whileTap={{scale: 0.96, transition:{duration: 0.1}}}
                        onClick={handleCopy}
                    >
                        {InfoData.message}
                        <i className={`bx ${copied ? 'bxs-copy' : 'bx-copy'}`}></i>
                    </motion.button>
                </div>
            }

            <div className="succ_description">
            </div>
            <div className="succ_button">
            <a target="_blank" rel="noopener noreferrer" href="https://digiseller.market/info/">Оставить отзыв</a>
            </div>
        </div>
    )
}

export default SuccStatusCard;