import React, { useEffect, useState } from 'react';

function Timer({ initialTime }) {
    const [time, setTime] = useState(initialTime);

    useEffect(() => {
        let interval;
        if (time > 0) {
            interval = setInterval(() => {
                setTime((prevTime) => prevTime - 1);
            }, 1000);
        }

        // Clear the interval when the component unmounts or when the timer reaches 0
        return () => clearInterval(interval);
    }, [time]);

    const formatTime = (seconds) => {
        const minutes = Math.floor(seconds / 60);
        const remainingSeconds = seconds % 60;
        return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
    };

    return time > 0 ? <div className="timer">{formatTime(time)}</div> : null;
}

export default Timer;
