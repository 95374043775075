import {useState, useEffect} from "react";
import {getToServer} from "../ToServer/getToServer";
import ServerLink from "../ServerLink";
import Loader from "../Loader";
import {FinancialContent} from "./financialContent";

const Financial = () => {
    const [financialData, setFinancialData] = useState({ data: [], isLoading: true, error: null });

    useEffect(() => {
        getToServer('financial', ServerLink().TableUrl, '', setFinancialData, () => {}, 'noSilent');
    }, []);




    return(
        <>
            {financialData.isLoading ? (
                <Loader/>
            ):(
                <>
                    <FinancialContent
                        financialData={financialData}
                    />
                </>
            )}
        </>
    )
}

export default Financial;