export const getToServer = async (object, url, data, setData, onFinish, onSilent) => {
    if (onSilent !== 'silent') {
        setData(prev => ({...prev, isLoading: true}))
    }; // Если передается, то анимации загрузки нет
    const requestData = {
        object: object
    };

    try{
        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                "Authorization": "Bearer " + localStorage.getItem("access_token")
            },
            body: JSON.stringify(requestData)
        })
            .then((response) => {
                if (response.status === 401) {
                    localStorage.setItem("access_token", null);
                    localStorage.setItem("isAuthenticated", false);
                    throw new Error('Unauthorized');
                }
                return response.json();
            })
            .then((responseData) => {
                if (responseData.success !== true) {
                    const errorMessage = 'Ошибка загрузки данных с сервера';
                    setData({ data: responseData.data, isLoading: false, error: errorMessage });
                    return
                }
                setData({ data: responseData.data, isLoading: false, error: null }); // А тут возвращается дата и останавливается анимация
                if (onFinish) onFinish(); // Анимация успеха действия
            })
            .catch((error) => {
                setData({ data: [], isLoading: false, error: error.toString() });
                if (onFinish) onFinish(); // Анимация ошибки действия
            });
    }catch (error){
        setData({ data: [], isLoading: false, error: error.toString() });
        if (onFinish) onFinish(); // Анимация ошибки действия
    }
};