export const sendToServer = async (object, url, data, onFinish) => {
    const requestData = {
        object: object,
        params: data
    };

    fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
            "Authorization": "Bearer " + localStorage.getItem("access_token")
        },
        body: JSON.stringify(requestData)
    })
        .then((response) => response.json())
        .then((responseData) => {
            if (onFinish) onFinish();
        })
        .catch((error) => {
            if (onFinish) onFinish();
        });
};