import React, { useState } from 'react';
import AccountNode from './AccountNode';
import {Reorder} from "framer-motion";
import {ReactComponent as GrabButton} from "../../img/mount.fill.svg";
import {useError} from "../Error/errorContext";


export const AccountsContent = ({ tableData, setTableData, handleParentIdChange }) => {
    const [draggedAccountId, setDraggedAccountId] = useState(null);
    const {setError} = useError();

    const handleDragStart = (accountId) => {
        setDraggedAccountId(accountId);
    };

    const handleDragEnd = () => {
        setDraggedAccountId(null);
    };

    const handleDrop = (targetAccountId) => {
        if (draggedAccountId === targetAccountId) {
            setError("Нельзя перетащить элемент на самого себя.");
            return;
        }

        const accountToMove = findAccount(tableData, draggedAccountId);
        if (!accountToMove) {
            setError("Аккаунт для перемещения не найден.");
            return;
        }

        if (!targetAccountId) {
            moveAccountToRoot(accountToMove);
        } else {
            if (isDescendant(accountToMove, targetAccountId)) {
                setError("Нельзя переместить аккаунт в его собственный подчиненный элемент.");
                return;
            }

            const targetAccount = findAccount(tableData, targetAccountId);
            if (!targetAccount) {
                setError("Целевой аккаунт не найден.");
                return;
            }

            if (targetAccount.children && targetAccount.children.length > 0) {
                setError("У целевого аккаунта уже есть дочерний элемент.");
                return;
            }

            moveAccountToTarget(targetAccount, accountToMove);
        }

        setDraggedAccountId(null);
    };

    const findAccount = (accounts, accountId) => {
        for (let account of accounts) {
            if (account.accountId === accountId) {
                return account;
            }
            if (account.children && account.children.length > 0) {
                const found = findAccount(account.children, accountId);
                if (found) return found;
            }
        }
        return null;
    };

    const removeAccount = (accounts, accountId) => {
        for (let i = 0; i < accounts.length; i++) {
            if (accounts[i].accountId === accountId) {
                return accounts.splice(i, 1)[0];
            }
            if (accounts[i].children && accounts[i].children.length > 0) {
                const removed = removeAccount(accounts[i].children, accountId);
                if (removed) return removed;
            }
        }
        return null;
    };

    const isDescendant = (account, targetId) => {
        if (account.accountId === targetId) return true;
        if (account.children && account.children.length > 0) {
            for (let child of account.children) {
                if (isDescendant(child, targetId)) return true;
            }
        }
        return false;
    };

    const moveAccountToRoot = (accountToMove) => {
        const removedAccount = removeAccount(tableData, draggedAccountId);
        if (!removedAccount) {
            setError("Не удалось найти и удалить аккаунт.");
            return;
        }

        const existingIndex = tableData.findIndex(account => account.accountId === draggedAccountId);
        if (existingIndex !== -1) {
            tableData.splice(existingIndex, 1);
            // console.log(`Существующий аккаунт с ID ${draggedAccountId} был удален из корня.`);
        }

        tableData.push(removedAccount);
        handleParentIdChange(tableData);
        // console.log(`Перетащили аккаунт с ID ${draggedAccountId} в корень.`);
        // console.log(tableData);
    };

    const moveAccountToTarget = (targetAccount, accountToMove) => {
        removeAccount(tableData, draggedAccountId);

        if (!targetAccount.children) {
            targetAccount.children = [];
        }
        targetAccount.children.push(accountToMove);

        handleParentIdChange(tableData);

        // console.log(`Перетащили аккаунт с ID ${draggedAccountId} на аккаунт с ID ${targetAccount.accountId}`);
    };

    return (
        <div className="account_container">
            <div
                className="free_area"
                onDragOver={(e) => e.preventDefault()}
                onDrop={() => handleDrop(null)}
            >
                Перетащите сюда для перемещения в свободную область
            </div>
            <div className="account_table">
                <Reorder.Group as='div' axis="y" values={tableData} onReorder={(newOrder) => setTableData({ ...tableData, data: newOrder })}>
                    {tableData.map((account) => (
                        <Reorder.Item as='div' key={account.accountId} value={account}
                                      // dragListener={false}
                                      // dragControls={controls}
                                      transition={{ duration: 0.2 }}
                        >
                            <div
                                className="account_main_row"
                            >
                                <div
                                    // onPointerDown={(e) => controls.start(e)}
                                    className="reorder_handle"
                                >
                                    <GrabButton/>
                                </div>
                                <AccountNode
                                    account={account}
                                    draggedAccountId={draggedAccountId}
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}
                                    onDrop={handleDrop}
                                />
                            </div>
                        </Reorder.Item>
                    ))}
                </Reorder.Group>
            </div>
        </div>
    );
};