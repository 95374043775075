
export const SteamNewPrice = ({newPrice, region, newPriceRub}) => {

    const currencySymbols = {
        'RU': '₽',
        'AZ': '$',
        'KZ': '₸',
        'UA': '₴',
        'default': '?'
    };
    const currencySymbol = currencySymbols[region] || currencySymbols['default'];

    return(
        <span>
            {newPrice}{currencySymbol}
            {region === 'RU' ? '' : `/${newPriceRub}₽`}
        </span>
    )
}