import {useState, useEffect} from "react";
import {getToServer} from "../ToServer/getToServer";
import serverLink from "../ServerLink";
import {ExchangeContent} from "./exchangeContent";

const Exchange = () => {
    const [exchangeData, setExchangeData] = useState({data: [], isLoading: true, errors: null})

    useEffect(() => {
        getToServer('exchange', serverLink().TableUrl, '', setExchangeData, () => {}, 'noSilent')
    }, []);

    return(
        <ExchangeContent
            exchangeData={exchangeData}
        />
    )
}

export default Exchange;