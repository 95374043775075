import {motion} from "framer-motion";
import {useState} from "react";
export const Parameters = ({service}) => {
    const [isVisible, setVisible] = useState(false);
    const handleVisible = () => {
        setVisible(!isVisible);
    }
    return (
        <span className={'parameters_box'}>
            <motion.span
                animate={{height: isVisible ? '100%' : '40px'}}
                className='service_box'>
                {service.parameter_ids.split(',').map((id, idx) => (
                    <span
                        className="serviceSpan"
                        key={idx}>
                        <span className="serviceSpanId api">{id}</span>
                        <span className="serviceSpanId">{service.digi_param_ids.split(',')[idx]}</span>
                        {service.parameter_titles.split(',')[idx]}
                    </span>
                ))}
            </motion.span>
            <motion.span
                className={'parameters_hidden bx bxs-chevron-down'}
                whileTap={{scale: 0.9}}
                whileHover={{scale: 1.2}}
                animate={{rotate: isVisible ? 180 : 0}}
                onClick={handleVisible}>
            </motion.span>
        </span>
    )
}