
export const ExchangeContent = ({exchangeData}) => {
    return(
        <div className='table_content'>
            <table className="tableFinancial">
                <thead>
                <tr>
                    <th>Рег.</th>
                    <th>Рейт</th>
                    <th>Дата обновления</th>
                </tr>
                </thead>
                <tbody>
                    {exchangeData.data.map(data => (
                        <tr key={data.id}>
                            <td>{data.currencyCode}</td>
                            <td>{data.rateToUsd}</td>
                            <td>{data.lastUpdated}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
)
}