import {Link} from 'react-router-dom';
import AnimatedPage from "./animatedPage";
function Main(){

    return(
        <AnimatedPage>
            <main>
                <div
                    className="main_wrapper">
                    <div className="main_card">
                        <div className="card">
                            <div className="main_card">
                                <div className="card_row">
                                    <div className="main_img">
                                        <i className='bx bxs-timer'></i>
                                    </div>
                                </div>
                                <div className="card_row">
                        <span className="main_text">
                            <span><Link to="/">OSON</Link></span>
                            <span>Сервис автоматической доставки и пополнения игр</span>
                        </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </AnimatedPage>
    );
}

export default Main;