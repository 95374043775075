import React from "react";
import { useAuth } from "../Components/AuthProvider";
import Control from "../Components/Control";
import LoginForm from "../Components/LoginForm";
import AnimatedPage from "./animatedPage";

function Admin({modal, setModal, dragOffset, setDragOffset, modalPositions, setModalPositions, modalSize, setModalSize, modalConfigs}) {
    const { isAuthenticated } = useAuth();

    return (
        <AnimatedPage>
            {isAuthenticated ? <Control modal={modal} setModal={setModal} dragOffset={dragOffset} setDragOffset={setDragOffset} modalPositions={modalPositions} setModalPositions={setModalPositions} modalSize={modalSize} setModalSize={setModalSize} modalConfigs={modalConfigs} /> : <LoginForm/>}
        </AnimatedPage>
    );
}

export default Admin;
