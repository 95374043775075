

function QiwiProgressCard ({InfoData}) {

    return(
        <>
            <div className="status_succ_card">
                <div className="succ_image">
                <span className="checkIcon">
                    <i className='bx bx-time-five'></i>
                </span>
                </div>
                <div className="succ_title">
                    {InfoData.status}
                </div>
                <div className="succ_description">
                    Пожалуйста, учтите, что время ожидания может доходить до 30 минут. После рекомендуем обратиться к нам!
                </div>
                <div className="succ_button">
                    <a target="_blank" rel="noopener noreferrer" href="https://digiseller.market/info/">Связаться с продавцом</a>
                </div>
            </div>
        </>
    )
}

export default QiwiProgressCard